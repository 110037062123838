import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { SecureInnerPagesGuard } from './guards/secure-inner-pages.guard';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home/tabs/taps',
    pathMatch: 'full'
  },
  {
    path: 'settings',
    canActivate: [SecureInnerPagesGuard],
    loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule)
  },
  {
    path: 'login',
    // canActivate: [AuthGuard],
    loadChildren: () => import('./pages/authentication/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'forgot-password',
    // canActivate: [AuthGuard],
    loadChildren: () => import('./pages/authentication/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
  },
  {
    path: 'register',
    // canActivate: [AuthGuard],
    loadChildren: () => import('./pages/authentication/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then(m => m.HomePageModule),
    canActivate: [SecureInnerPagesGuard]
  },
  {
    path: 'taps',
    loadChildren: () => import('./pages/tap/tap.module').then(m => m.TapPageModule)
  },
  {
    path: 'kegs',
    loadChildren: () => import('./pages/keg/keg-routing').then(m => m.ROUTES)
  },
  {
    path: 'inventory',
    canActivate: [SecureInnerPagesGuard],
    loadChildren: () => import('./pages/inventory/inventory.module').then(m => m.InventoryPageModule)
  },
  {
    path: 'checkout',
    canActivate: [SecureInnerPagesGuard],
    loadChildren: () => import('./pages/home/checkout/checkout.module').then(m => m.CheckoutPageModule)
  },
  // {
  //   path: 'stripe',
  //   canActivate: [SecureInnerPagesGuard],
  //   loadChildren: () => import('./pages/stripe/stripe.module').then( m => m.StripePageModule)
  // },
  {
    path: 'verify-email',
    loadChildren: () => import('./pages/authentication/verify-email/verify-email.module').then(m => m.VerifyEmailPageModule)
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then(m => m.HomePageModule),
    canActivate: [SecureInnerPagesGuard]
  },
  {
    path: 'taps',
    loadChildren: () => import('./pages/tap/tap.module').then(m => m.TapPageModule)
  },
  {
    path: 'inventory',
    canActivate: [SecureInnerPagesGuard],
    loadChildren: () => import('./pages/inventory/inventory.module').then(m => m.InventoryPageModule)
  },
  {
    path: 'checkout',
    canActivate: [SecureInnerPagesGuard],
    loadChildren: () => import('./pages/home/checkout/checkout.module').then(m => m.CheckoutPageModule)
  },
  // {
  //   path: 'stripe',
  //   canActivate: [SecureInnerPagesGuard],
  //   loadChildren: () => import('./pages/stripe/stripe.module').then(m => m.StripePageModule)
  // },
  {
    path: 'ontap',
    loadChildren: () => import('./pages/tv/basic/basic.module').then(m => m.OnTapBasicPageModule)
  },
  {
    path: 'subscription',
    loadChildren: () => import('./pages/subscriptions/subscription/subscription.module').then(m => m.SubscriptionPageModule)
  },
  {
    path: 'subscribe',
    loadChildren: () => import('./pages/subscriptions/subscribe/subscribe.module').then(m => m.SubscribePageModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminPageModule)
  },
  {
    path: 'tv',
    loadChildren: () => import('./pages/tv/tv.module').then(m => m.TvPageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/notification/notification.module').then(m => m.NotificationPageModule)
  },
  {
    path: 'stripe-subscribe',
    loadChildren: () => import('./pages/subscriptions/stripe-subscribe/stripe-subscribe.module').then( m => m.StripeSubscribePageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }
