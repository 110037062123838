import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { ICheers } from 'app/models/cheers';
import * as CheersActions from './actions'



export interface State {
  cheers: ICheers[];
  error: string;
}

export const initialState: State = {
  cheers: [],
  error: '',
};

export const stateName = 'cheers';

const getCheersFeatureState = createFeatureSelector<State>(stateName);
export const selectAllCheers = createSelector(
  getCheersFeatureState,
  state => state.cheers
);

export const getCheersForTap = (tapId: string) => createSelector(selectAllCheers, items => items.find(x => x.id === tapId))

export const reducer = createReducer<State>(
  initialState,
  on(
    CheersActions.upsertOneCheers, (state, action): State => {
      return {
        ...state,
        cheers: [action.tap]
      }
    }
  )
);
