import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastService } from 'app/services/toast.service';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import * as fromActions from './actions'
import { FontService } from 'app/services/dal/font.service';



@Injectable()
export class Effects {

  constructor(
    private actions$: Actions,
    private service: FontService,
    private toastService: ToastService
  ) { }

  getItems$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(fromActions.loadItems),
        mergeMap(() => this.service.getItems('name')
          .pipe(
            map(items => fromActions.loadItemsSuccess({ items: items })),
            catchError(error => of(fromActions.loadItemsFailure({ error })))
          )
        )
      );
  });



}
