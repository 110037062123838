import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { IBar } from 'app/models/bar';
import { IBarSettings } from 'app/models/barSettings';
import { AlertController, IonicModule } from '@ionic/angular';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { User } from 'firebase/auth';
import { PictureCropperComponent } from 'app/components/image-cropper/picture-cropper.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  imports: [CommonModule, PictureCropperComponent, FormsModule, ReactiveFormsModule, IonicModule],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormComponent implements OnChanges {

  @Input() user!: User;
  @Input() bar!: IBar;
  @Input() barSettings!: IBarSettings;
  @Output() updateBar = new EventEmitter<any>();
  @Output() updateBarSettings = new EventEmitter<any>();

  newApiKey!: string;

  form: FormGroup;

  constructor(
    private alertController: AlertController,
  ) {

    this.form = new FormGroup({
      barName: new FormControl('', Validators.required),
      displayFullscreenToggle: new FormControl(''),
      displayStatistics: new FormControl(''),
      displayKegPercent: new FormControl(''),
      darkMode: new FormControl(''),
      useWashing: new FormControl(''),
      washingTapsDays: new FormControl(''),
      washingUnusedTapsDays: new FormControl(''),
      allowTapNotifications: new FormControl(''),
      allowBeverageNotifications: new FormControl(''),
    });

  }



  ngOnChanges(changes: SimpleChanges) {
    if (changes['bar']?.currentValue) {
      this.patchFormFromBar(this.bar);
    }
    if (changes['barSettings']?.currentValue) {
      this.patchFormFromBarSettings(this.barSettings);
    }
  }

  saveBar() {
    this.populateBarFromForm();
    this.updateBar.emit(this.bar);
  }

  saveBarAvatar(bar: IBar, imageUrl: string) {
    this.bar = {
      ...bar,
      photoURL: imageUrl
    };
    this.updateBar.emit(this.bar);
  }

  // #region ******************* API Key **************/
  async copyApiKeyToClipboard(apiKey: string) {
    try {
      var successful = this.copyToClipboard(apiKey);

      var msg = successful
        ? "Copy to clipboard succesfully"
        : "Failed to copy to clipboard, you have to do it manually";
      // if (successful) await this.toastService.toastSuccess(msg);
      // else await this.toastService.error(msg);
    } catch (err) {
      console.error("Oops, unable to copy");
    }
  }

  hideApiKey() {
    this.newApiKey = "";
  }
  showApiKey() {
    this.newApiKey = this.barSettings.apiKey ?? '';
  }

  private copyToClipboard(val: string) {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    var ok = document.execCommand("copy");
    document.body.removeChild(selBox);
    return ok;
  }

  async generateApiKey() {
    const apiKey = this.generateKey(this.user.uid, this.barSettings.barId);
    this.barSettings = {
      ...this.barSettings,
      apiKey: apiKey
    };

    this.updateBarSettings.emit(this.barSettings);
    this.newApiKey = this.barSettings.apiKey ?? '';
  }

  private generateKey(userId: string, barId: string) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    const length = 32;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    const totKey = `${userId}:${barId}:${result}`;
    const btoaKey = btoa(totKey);
    return btoaKey;
  }

  async confirmDeleteApiKey() {
    const alert = await this.alertController.create({
      header: "Confirm!",

      message: "Are you sure you want to delete the API-key?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: "Okay",
          handler: () => {
            this.deleteApiKey();
          },
        },
      ],
    });

    await alert.present();
  }

  private async deleteApiKey() {
    this.barSettings = {
      ...this.barSettings,
      apiKey: ''
    };
    this.updateBarSettings.emit(this.barSettings);
  }
  // #endregion ******************* API Key **************/


  // #region FORM to ITEM
  private populateBarFromForm() {
    this.bar = {
      ...this.bar,
      name: this.form.controls['barName'].value,
      displayFullscreenToggle: this.form.controls['displayFullscreenToggle'].value ?? false,
      displayStatistics: this.form.controls['displayStatistics'].value ?? false,
      displayKegPercent: this.form.controls['displayKegPercent'].value ?? false,
      useWashing: this.form.controls['useWashing'].value ?? false,
      washingTapsDays: this.form.controls['washingTapsDays'].value ?? 0,
      washingUnusedTapsDays: this.form.controls['washingUnusedTapsDays'].value ?? 0,
      darkMode: this.form.controls['darkMode'].value ?? false,
      allowTapNotifications: this.form.controls['allowTapNotifications'].value ?? false,
      allowBeverageNotifications: this.form.controls['allowBeverageNotifications'].value ?? false
    }
  }

  private patchFormFromBar(bar: IBar) {
    this.form.patchValue({
      barName: bar.name,
      displayFullscreenToggle: bar.displayFullscreenToggle,
      displayStatistics: bar.displayStatistics,
      displayKegPercent: bar.displayKegPercent,
      useWashing: bar.useWashing,
      washingTapsDays: bar.washingTapsDays,
      washingUnusedTapsDays: bar.washingUnusedTapsDays,
      darkMode: bar.darkMode,
      allowTapNotifications: bar.allowTapNotifications,
      allowBeverageNotifications: bar.allowBeverageNotifications
    });
  }
  private patchFormFromBarSettings(barSettings: IBarSettings) {
    // this.form.patchValue({
    //   apiKey: barSettings.apiKey,
    //   // allBeerSortOrder: barSettings.allBeerSortOrder,
    //   // favBeerSortOrder: barSettings.favBeerSortOrder,
    // });
  }
  // #endregion FORM to ITEM

}
