import { createAction, props } from '@ngrx/store';
import { IOnTapTemplate } from 'app/models/on-tap-template';

export const clearAllItems = createAction('[DalHelper] Clear All onTap Templates');
export const loadItems = createAction('[OntTapTv Page] Load onTap Templates', props<{ barId: string }>());
export const loadItemsSuccess = createAction('[OnTapTemplate API] Load onTap Templates Success', props<{ a: IOnTapTemplate[], b: IOnTapTemplate[] }>());
export const loadItemsFailure = createAction('[OnTapTemplate API] Load onTap Templates Failure', props<{ error: any }>());


export const setItem = createAction('[OntTapTv Page] Set selected onTap Templates', props<{ id: string }>());

export const addItem = createAction('[OntTapTv Page] Add onTap Templates', props<{ id: string, item: IOnTapTemplate }>());
export const addItemSuccess = createAction('[OnTapTemplate API] Add onTap Templates Success');
export const addItemFailure = createAction('[OnTapTemplate API] Add onTap Templates Failure', props<{ error: any }>());

export const deleteItem = createAction('[OntTapTv Page] Delete onTap Templates', props<{ id: string }>());
export const deleteItemSuccess = createAction('[OnTapTemplate API] Delete onTap Templates Success', props<{ id: string }>());
export const deleteItemFailure = createAction('[OnTapTemplate API] Delete onTap Templates Failure', props<{ error: any }>());

// export const updateItem = createAction('[OntTapTv Page] Update onTap Templates', props<{ item: IOnTapTemplate }>());
// export const updateItemSuccess = createAction('[OnTapTemplate API] Update onTap Templates Success');
// export const updateItemFailure = createAction('[OnTapTemplate API] Update onTap Templates Failure', props<{ error: any }>());

export const softUpdate = createAction('[OntTapTv Page] Soft Update Tap', props<{ id: string, data: any, uid: string }>());
export const softUpdateSuccess = createAction('[OnTapTemplate API] Soft Update Tap Success');
export const softUpdateFailure = createAction('[OnTapTemplate API] Soft Update Tap Failure', props<{ error: any }>());
