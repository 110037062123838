import { format, compareAsc, compareDesc, formatDistanceToNow, formatISO, fromUnixTime } from 'date-fns';

export function formatDate(date: Date | undefined | null, formatting: string): string {
  if (!date) return '';

  return format(date, formatting);
}

export function lessThanOrEqualTo(date1: Date | undefined | null, date2: Date | undefined | null): boolean {
  if (!(date1 && date2)) return false;

  const result = compareDesc(date1, date2);
  return result <= 0;
}

export function greaterThanOrEqualTo(date1: Date | undefined | null, date2: Date | undefined | null): boolean {
  if (!(date1 && date2)) return false;

  const result = compareAsc(date1, date2);
  return result <= 0;
}

export function distanceToNow(date: Date): string {
  return formatDistanceToNow(date, { addSuffix: true });
}

export function convertDDMMYYYYToDate(dateString: string): Date | null {
  if (!dateString) {
    return null;
  }

  const dateArray = dateString.split('.');
  const date = new Date(+dateArray[2], +dateArray[1] - 1, +dateArray[0] + 1);
  return date;
}

export function getISOString(dateString: string): string | null {
  if (!dateString) {
    return null;
  }
  const retDate = formatISO(dateString);
  if (retDate) {
    return retDate;
  }

  const unixDate = fromUnixTime(+dateString / 1000 as any);
  return formatISO(unixDate);

}

export function toISOString(): string {
  return formatISO(new Date());;
}

export function removeTimeFromDate(value: any): Date {
  const dateAndTime = new Date(value);
  const dateWithoutTime = new Date(dateAndTime.toDateString());
  return dateWithoutTime;
}

export function dateInThePastWithoutTime(value: any): boolean {
  if (!value) {
    return false;
  }
  const dateWithoutTime = removeTimeFromDate(value);
  return dateWithoutTime <= new Date();
}

export function calendar(dDate: Date | undefined | null) {
  if (!dDate) return '';

  return formatDistanceToNow(dDate, { includeSeconds: false, addSuffix: true });
}


export function momentCalendarWithoutTime(value: any): string {
  return formatDistanceToNow(value, { addSuffix: true, includeSeconds: false });
  //  moment(value).calendar(null, {
  //   lastDay: '[Yesterday]',
  //   sameDay: '[Today]',
  //   nextDay: '[Tomorrow]',
  //   lastWeek: '[Last] dddd',
  //   nextWeek: 'dddd',
  //   sameElse: 'L'
  // });
}
