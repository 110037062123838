import { createAction, props } from '@ngrx/store';
import { ISubscription } from 'app/models/subscription';

export const loadItem = createAction('[App Module Page] Load Subscription', props<{ barId: string }>());
export const gotoSubscribe = createAction('[Subscription Effect] Goto Subscribe');

export const loadItemSuccess = createAction('[Subscription API] Load Subscription Success', props<{ item: ISubscription }>());

export const loadItemFailure = createAction('[Subscription API] Load Subscription Failure', props<{ error: any }>());

export const updateItem = createAction('[App Module Page] Update Subscription', props<{ item: ISubscription }>());
export const updateItemSuccess = createAction('[Subscription API] Update Subscription Success');
export const updateItemFailure = createAction('[Subscription API] Update Subscription Failure', props<{ error: any }>());

export const addItem = createAction('[App Module Page] Add Subscription', props<{ item: ISubscription }>());
export const addItemSuccess = createAction('[Subscription API] Add Subscription Success', props<{ item: ISubscription }>());
export const addItemFailure = createAction('[Subscription API] Add Subscription Failure', props<{ error: any }>());
