import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionHandler {

  constructor() { }


  subs: Subscription[] = [];


  addSubscription(sub: Subscription) : void {
    this.subs.push(sub);
  }

  clearSubscriptions() : void {
    for(let i=0; i<this.subs.length; i++) {
      let subscription: Subscription = this.subs[i];
      if(subscription) {
        subscription.unsubscribe();
      }
    }
    // this.subs.length = 0;
    this.subs.splice(0,this.subs.length);
  }

}
