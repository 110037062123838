import { createAction, props } from '@ngrx/store';
import { IBar } from 'app/models/bar';

export const loadBar = createAction('[Bar Page] Load Bar', props<{ barId: string }>());
export const loadBarSuccess = createAction('[Bar API] Load Bar Success', props<{ bar: IBar }>());
export const loadBarFailure = createAction('[Bar API] Load Bar Failure', props<{ error: any }>());

export const updateBar = createAction('[Bar Page] Update Bar', props<{ bar: IBar }>());
export const updateBarSuccess = createAction('[Bar API] Update Bar Success', props<{ bar: IBar }>());
export const updateBarFailure = createAction('[Bar API] Update Bar Failure', props<{ error: any }>());

export const softUpdate = createAction('[Bar API] Soft Update Bar', props<{ id: string, data: any, uid: string }>());
export const softUpdateSuccess = createAction('[Bar API] Soft Update Bar Success', props<{ id: string, data: any }>());
export const softUpdateFailure = createAction('[Bar API] Soft Update Bar Failure', props<{ error: any }>());

