import { createAction, props } from '@ngrx/store';
import { IBeerStyle } from 'app/models/beerstyle';

export const clearAllItems = createAction('[DalHelper] Clear All BeerStyles');
export const loadItems = createAction('[Module Component] Load BeerStyles', props<{ barId: string }>());
// export const loadItemsSorted = createAction('[Taps Page] Load Beverages', props<{ barId: string, sortOrder: string }>());
export const loadItemsSuccess = createAction('[Beerstyle API] Load BeerStyles Success', props<{ items: IBeerStyle[] }>());
export const loadItemsFailure = createAction('[Beerstyle API] Load BeerStyles Failure', props<{ error: any }>());


export const setItem = createAction('[Beverage Page] Set selected BeerStyle', props<{ id: string }>());

export const addItem = createAction('[Search BrewFather / BeerStyle Page] Add BeerStyle', props<{ id: string, item: IBeerStyle }>());
export const addItemSuccess = createAction('[Beerstyle API] Add BeerStyles Success', props<{ item: IBeerStyle }>());
export const addItemFailure = createAction('[Beerstyle API] Add BeerStyles Failure', props<{ error: any }>());

export const deleteItem = createAction('[Beverage Detail Page] Delete BeerStyle', props<{ id: string }>());
export const deleteItemSuccess = createAction('[Beerstyle API] Delete BeerStyles Success', props<{ id: string }>());
export const deleteItemFailure = createAction('[Beerstyle API] Delete BeerStyles Failure', props<{ error: any }>());
