import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BarSettingsService } from 'app/services/dal/bar-settings.service';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap, tap, concatMap } from 'rxjs/operators';
import * as fromActions from './actions'
import { ToastService } from '../../services/toast.service';



@Injectable()
export class BarSettingsEffects {

  constructor(
    private actions$: Actions,
    private barSettingsService: BarSettingsService,
    private toastService: ToastService) { }

  getBar$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(fromActions.loadItems),
        mergeMap((action) => this.barSettingsService.getItems(action.barId)
          .pipe(
            map(items => fromActions.loadItemsSuccess({ items: items })),
            catchError(error => of(fromActions.loadItemsFailure({ error })))
          )
        )
      );
  });

  getBarSetting$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(fromActions.loadItem),
        mergeMap((action) => from(this.barSettingsService.getItemPromise(action.barId))
          .pipe(
            map(item => fromActions.loadItemSuccess({ item: item })),
            catchError(error => of(fromActions.loadItemFailure({ error })))
          )
        )
      );
  });


  updateBar$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(fromActions.updateItem),
        concatMap((action) => of(this.barSettingsService.softUpdate(action.update.id.toString(), action.update.changes))
          .pipe(
            map(() => {
              const result = fromActions.updateItemSuccess({ update: action.update });
              this.toastService.saveSuccess();
              return result;
            }),
            catchError(error => of(fromActions.updateItemFailure({ error })))
          )
        )
      );
  });


  handleFetchError$ = createEffect(
    () => {
      return this.actions$
        .pipe(
          ofType(fromActions.loadItemsFailure),
          tap(async (action) => {
            await this.toastService.error(action.error)
          }),
        )
    },
    { dispatch: false }
  );



}
