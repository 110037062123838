import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { ISubscription } from 'app/models/subscription';
import * as AppState from '../app.state';
import * as Actions from './actions'
import * as fromStripeSubscription from 'app/state/stripeSubscription';
import { of } from 'rxjs';
import * as fromStripePrice from 'app/state/stripePrice';
import * as fromStripeProduct from 'app/state/stripeProduct';

export interface State extends AppState.State {
  subscription?: ISubscription;
  error: string;
}

const initialState: State = {
  subscription: undefined,
  error: ''
};

export const stateName = 'subscriptions';
const getItemFeatureState = createFeatureSelector<State>(stateName);

//Selectors
export const getItem = createSelector(
  getItemFeatureState,
  state => state.subscription
);

//Selectors
export const getHasPremium = createSelector(
  getItemFeatureState,
  fromStripeSubscription.selectAllItems,
  (state, stripeSubs) => {
    let subDate = new Date(state.subscription?.billingPeriodEnds ?? '');

    for (let item of stripeSubs) {
      const date = new Date(item.current_period_end);
      if (date > subDate) {
        subDate = date;
      }
    }
    // if (!isNaN(date.getTime())) {
    //   return false;
    // }

    return subDate > new Date();
  }
);


//Selectors
export const getNewestSubscription = createSelector(
  getItemFeatureState,
  fromStripeSubscription.selectAllItems,
  fromStripePrice.selectAllEntities,
  fromStripeProduct.selectAllEntities,
  (state, stripeSubs, prices, products) => {

    let subDate = new Date(state.subscription?.billingPeriodEnds ?? '');
    let retSub = state.subscription;
    if (retSub) {
      retSub = {
        ...retSub,
        name: (retSub.billingPeriodEnds ?? new Date()) < new Date() ? 'Free' : retSub.name,
        status: (retSub.billingPeriodEnds ?? new Date()) < new Date() ? 'Free' : retSub.status,
      };
    }

    stripeSubs = stripeSubs.filter(x => x.status === 'active' || x.status === 'trialing');

    for (let item of stripeSubs) {
      subDate = new Date(1, 1, 1);
      const date = new Date(item.current_period_end);

      if (date >= subDate) {
        subDate = date;
        let amount = 0;
        let name = '';

        if (prices[item.price]?.unit_amount) {
          amount = prices[item.price]?.unit_amount ?? 0;
        }
        if (products[item.product]?.name) {
          name = products[item.product]?.name ?? item.status;
        }

        retSub = {
          billingPeriodEnds: new Date(item.current_period_end),
          status: item.status,
          barId: retSub?.barId ?? '',
          cancelAtPeriodEnd: item.cancel_at_period_end,
          orderId: item.id,
          id: retSub?.id ?? '',
          amount: amount.toString() ?? '0',
          name: name,

        }
      }
    }


    return retSub;
  }
);


export const reducer = createReducer<State>(
  initialState,
  on(Actions.loadItemSuccess, (state, action): State => {
    return {
      ...state,
      subscription: action.item,
      error: ''
    };
  }),
  on(Actions.loadItemFailure, (state, action): State => {
    return {
      ...state,
      subscription: undefined,
      error: action.error
    };
  }),
);


export const hasValidSupscription = createSelector(
  fromStripeSubscription.selectAllItems,
  getItemFeatureState,
  ((stripeSubs, state) => {

    return (state.subscription &&
      state.subscription?.status == 'Active') ||
      !!stripeSubs.find(x => x.status === 'active')
  }
  )
);

