import { Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { IBeverage, IBeverageVM } from '../../models/beverage';
import { IKeg, KegItem } from '../../models/keg';
import { dbConst } from './databaseConstants';
import * as firebase from 'firebase/app';
import { AuthService } from '../auth.service';
import { FirestoreCrudService } from './crudService';
import { collection, collectionData, Firestore, orderBy, query, where, writeBatch, doc, Timestamp } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import DalHelper from '../helpers/string.helper';
import { IBottle } from 'app/models/bottle';
import { KegService } from './keg.service';
import { ICan } from 'app/models/can';
import * as dateHelper from 'app/helpers/date.helpers';

@Injectable({
  providedIn: 'root',
})
export class BeverageService {

  private crudService: FirestoreCrudService<IBeverage>;

  constructor(
    private authService: AuthService,
    // private dateHelper: DateHelper,
    private firestore: Firestore,
  ) {
    this.crudService = new FirestoreCrudService<IBeverage>(firestore, authService, dbConst.beverages);
  }

  getNewId(): string {
    return this.crudService.getNewId();
  }



  async create(item: IBeverage, id?: string) {
    const addItem: IBeverage = {
      ...item,
      bottleDate: item.bottleDate ? new Date(item.bottleDate) : null,
      bestBeforeDate: item.bestBeforeDate ? new Date(item.bestBeforeDate) : null,
    }

    return await this.crudService.add(addItem, id);
  }

  async softUpdate(id: string, data: any, uid: string) {
    return await this.crudService.softUpdate(id, data, uid);
  }

  async updateItem(item: IBeverage) {

    const updateItem = JSON.parse(JSON.stringify(item));

    updateItem.bottleDate = item.bottleDate ? new Date(item.bottleDate) : null;
    updateItem.bestBeforeDate = item.bestBeforeDate ? new Date(item.bestBeforeDate) : null;


    if (updateItem.bottles) {
      updateItem.bottles.forEach((bottle: IBottle) => {
        bottle.bestBeforeDate = bottle.bestBeforeDate ? new Date(bottle.bestBeforeDate) : undefined;;
        bottle.filledDate = bottle.filledDate ? new Date(bottle.filledDate) : undefined;;
        bottle.notificationDate = bottle.notificationDate ? new Date(bottle.notificationDate) : undefined;;
      });
    }

    if (updateItem.cans) {
      updateItem.cans.forEach((can: ICan) => {
        can.bestBeforeDate = can.bestBeforeDate ? new Date(can.bestBeforeDate) : undefined;;
        can.filledDate = can.filledDate ? new Date(can.filledDate) : undefined;;
        can.notificationDate = can.notificationDate ? new Date(can.notificationDate) : undefined;
      });
    }
    return await this.crudService.update(updateItem);
  }



  async update(item: IBeverage, kegs: IKeg[] | null) {
    const user = await this.authService.getUser();
    const saveItem: IBeverage = {
      ...item,
      uBy: user?.uid,
      uDate: new Date()
    };

    if (!item.id) {
      return;
    }

    const batch = writeBatch(this.firestore);
    const itemPath = DalHelper.getItemPath(dbConst.beverages, item.id);


    saveItem.bottleDate = item.bottleDate ? new Date(item.bottleDate) : null;
    saveItem.bestBeforeDate = item.bestBeforeDate ? new Date(item.bestBeforeDate) : null;


    if (saveItem.bottles) {
      saveItem.bottles.forEach(bottle => {
        bottle.bestBeforeDate = bottle.bestBeforeDate ? new Date(bottle.bestBeforeDate) : undefined;
        bottle.filledDate = bottle.filledDate ? new Date(bottle.filledDate) : undefined;
        bottle.notificationDate = bottle.notificationDate ? new Date(bottle.notificationDate) : undefined;
      });
    }

    if (saveItem.cans) {
      saveItem.cans.forEach(can => {
        can = {
          ...can,
          bestBeforeDate: can.bestBeforeDate ? new Date(can.bestBeforeDate) : undefined,
          filledDate: can.filledDate ? new Date(can.filledDate) : undefined,
          notificationDate: can.notificationDate ? new Date(can.notificationDate) : undefined
        }
      });
    }



    const itemDoc = doc(this.firestore, itemPath);
    batch.set(itemDoc, saveItem);
    if (kegs && kegs.length > 0) {
      const saveKegs: IKeg[] = {
        ...kegs
      }
      saveKegs.forEach((keg) => {
        keg.uBy = item.uBy;
        keg.uDate = item.uDate;
        //keg.beverageName = item.name;
        keg.beverageId = item.id ?? null;
        keg.tapDate = keg.tapDate ? new Date(keg.tapDate) : null;
        keg.cleanDate = keg.cleanDate ? new Date(keg.cleanDate) : null;
        keg.bestBeforeDate = keg.bestBeforeDate ? new Date(keg.bestBeforeDate) : null;

        if (!!keg.id) {
          const kegPath = DalHelper.getItemPath(dbConst.kegs, keg.id);
          const kegDoc = doc(this.firestore, kegPath);
          batch.set(kegDoc, keg);
        }
      });
    }


    return batch.commit();
  }




  async delete(id: string) {
    if (id) {
      await this.crudService.delete(id);
    }
  }


  getItem(id: string) {
    return this.crudService.get(id)
      .pipe(
        map(a => {
          const data = a as IBeverage;
          if (data.bottles) {
            data.bottles.forEach(item => {
              Object.keys(item).filter((key: string) => (item as any)[key] instanceof Timestamp)
                .forEach(key => {
                  (item as any)[key] = (item as any)[key].toDate()
                })
              item.expired = dateHelper.dateInThePastWithoutTime(item.bestBeforeDate);
              item.notify = dateHelper.dateInThePastWithoutTime(item.notificationDate);

            })
          }
          if (data.cans) {
            data.cans.forEach(item => {
              Object.keys(item).filter((key: string) => (item as any)[key] instanceof Timestamp)
                .forEach(key => {
                  (item as any)[key] = (item as any)[key].toDate()
                })
              item.expired = dateHelper.dateInThePastWithoutTime(item.bestBeforeDate);
              item.notify = dateHelper.dateInThePastWithoutTime(item.notificationDate);
            })
          }

          return data;
        })
      );
  }

  getItems(barId: string, limit = undefined) {
    return this.crudService
      .getItems(barId, limit)
      .pipe(
        map(changes => {
          return changes.map(a => {
            const data = a as IBeverage;
            if (data.bottles) {
              data.bottles.forEach(item => {
                Object.keys(item).filter((key: string) => (item as any)[key] instanceof Timestamp)
                  .forEach(key => {
                    (item as any)[key] = (item as any)[key].toDate()
                  })
                item.expired = dateHelper.dateInThePastWithoutTime(item.bestBeforeDate);
                item.notify = dateHelper.dateInThePastWithoutTime(item.notificationDate);
              })
            }
            if (data.cans) {
              data.cans.forEach(item => {
                Object.keys(item).filter((key: string) => (item as any)[key] instanceof Timestamp)
                  .forEach(key => {
                    (item as any)[key] = (item as any)[key].toDate()
                  })
                item.expired = dateHelper.dateInThePastWithoutTime(item.bestBeforeDate);
                item.notify = dateHelper.dateInThePastWithoutTime(item.notificationDate);
              })
            }
            return data;
          });
        })
      )
  }

  getItemsOrderBy(barId: string, sortOrder: string, limit = null) {
    return this.crudService
      .getItemsOrderBy(barId, sortOrder, 'asc', limit ?? 1000)
      .pipe(
        map(changes => {
          return changes.map(a => {
            const data = a as IBeverage;
            if (data.bottles) {
              data.bottles.forEach(item => {
                Object.keys(item).filter((key: string) => (item as any)[key] instanceof Timestamp)
                  .forEach(key => {
                    (item as any)[key] = (item as any)[key].toDate()
                  })
                item.expired = dateHelper.dateInThePastWithoutTime(item.bestBeforeDate);
                item.notify = dateHelper.dateInThePastWithoutTime(item.notificationDate);
              })
            }
            if (data.cans) {
              data.cans.forEach(item => {
                Object.keys(item).filter((key: string) => (item as any)[key] instanceof Timestamp)
                  .forEach(key => {
                    (item as any)[key] = (item as any)[key].toDate()
                  })
                item.expired = dateHelper.dateInThePastWithoutTime(item.bestBeforeDate);
                item.notify = dateHelper.dateInThePastWithoutTime(item.notificationDate);
              })
            }
            return data;
          });
        })
      )
  }

  getFavoriteItems(barId: string) {

    const listRef = collection(this.firestore, dbConst.beverages);
    const q = query(listRef, where('barId', '==', barId), where('favorite', '==', true), orderBy('name'));
    return collectionData(q, { idField: 'id' }) as Observable<IBeverage[]>;
  }

}
