import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { ILocation } from 'app/models/location';
import * as LocationActions from './actions'

export interface State extends EntityState<ILocation> {
  // additional entity state properties
  selectedId: string | null;
  error: string;
}


export function sortByName(a: ILocation, b: ILocation): number {
  return a.name.localeCompare(b.name);
}
export function selectId(a: ILocation): string {
  return a.id ?? '';
}
export const adapter: EntityAdapter<ILocation> = createEntityAdapter<ILocation>({
  sortComparer: false,
  selectId: selectId
});

export const initialState: State = adapter.getInitialState({
  selectedId: null,
  error: ''
});

export const stateName = 'location';

// Reducers
export const reducer = createReducer<State>(
  initialState,
  on(LocationActions.clearAllItems, (state) => {
    return adapter.removeAll(state);
  }),
  on(LocationActions.loadItemsSuccess, (state, { items }) => {
    return adapter.setAll(items, state);
  }),
  on(LocationActions.updateItem, (state, { item }) => {
    return adapter.setOne(item, state);
  }),
  on(LocationActions.setItem, (state, action) => Object.assign({ ...state, selectedId: action.id })),
  on(LocationActions.setItemFromListPage, (state, action) => Object.assign({ ...state, selectedId: action.id }))


);


// get the selectors
const {
  selectAll,
  selectEntities,
} = adapter.getSelectors();


const selectState = createFeatureSelector<State>(stateName);



export const getSelectedId = (state: State) => state.selectedId;
const getState = createFeatureSelector<State>(stateName);
export const getAllItems = createSelector(getState, selectAll);
export const selectAllEntities = createSelector(getState, selectEntities);
export const selectCurrentId = createSelector(getState, getSelectedId);
export const selectCurrentItem = createSelector(selectAllEntities, selectCurrentId, (entities, id) => {
  if (id) return entities[id];
  return null;
});
export const selectEntity = (id: string) => createSelector(selectAllEntities, entities => entities[id]);


// // export const selectAllEntities = createSelector(selectState, selectEntities);

// export const selectEntity = (id: string) => createSelector(
//   selectAllEntities,
//   entities => entities[id]
// );
