import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TapService } from 'app/services/dal/tap.service';
import { from, of } from 'rxjs';
import { catchError, mergeMap, map, tap } from 'rxjs/operators';
import * as TapActions from './actions'
import { ToastService } from '../../services/toast.service';
import { addItem, deleteItemSuccess } from './actions';
import { softUpdateFailure } from '../keg/actions';



@Injectable()
export class TapEffects {

  constructor(private actions$: Actions, private tapService: TapService, private toastService: ToastService) { }

  getTaps$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(TapActions.loadItems),
        mergeMap((action) => this.tapService.getItems(action.barId)
          .pipe(
            map(taps => TapActions.loadItemsSuccess({ items: taps })),
            catchError(error => of(TapActions.loadTapsFailure({ error })))
          )
        )
      );
  });


  softUpdate$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(TapActions.softUpdate),
        mergeMap((action) => from(this.tapService.softUpdate(action.id, action.data, action.uid))
          .pipe(
            map(() => {
              this.toastService.saveSuccess();
            }),
            catchError(error => of(TapActions.softUpdateFailure({ error })))
          )
        )
      );
  },
    { dispatch: false }
  );

  updateWithKeg$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(TapActions.updateWithKeg),
        mergeMap((action) => from(this.tapService.updateWithKeg(action.tap, action.keg))
          .pipe(
            map(() => {
              this.toastService.saveSuccess();
            }),
            catchError(error => of(TapActions.updateWithKegFailure({ error })))
          )
        )
      );
  },
    { dispatch: false }
  );


  create$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(TapActions.addItem),
        mergeMap((action) => from(this.tapService.create(action.item))
          .pipe(
            map(() => {
              this.toastService.saveSuccess();
            }),
            catchError(error => of(TapActions.addItemFailure({ error })))
          )
        )
      );
  },
    { dispatch: false }
  );

  delete$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(TapActions.deleteItem),
        mergeMap((action) => from(this.tapService.delete(action.item))
          .pipe(
            map(() => {
              this.toastService.deleteItemSuccess();
            }),
            catchError(error => of(TapActions.deleteItemFailure({ error })))
          )
        )
      );
  },
    { dispatch: false }
  );


}
