import { createEntityAdapter, EntityAdapter, EntityState, Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as Actions from './actions'
import * as fromRouter from '../router/router-state.selectors';
import { INotification, INotificationVM } from 'app/models/notification';
import { notificationConsts } from 'app/services/constants';
import * as fromKeg from 'app/state/keg/reducer';
import { environment } from 'environments/environment';
import { concat } from 'rxjs';

export interface State extends EntityState<INotification> {
  // additional entity state properties
  selectedId: string | null;
  error: string;
}

export function sortByUDate(a: INotification, b: INotification): number {
  return getTime(b.uDate) - getTime(a.uDate);
}

function getTime(date?: Date) {
  return date != null ? date.getTime() : 0;
}



export function selectId(a: INotification): string {
  return a.id ?? '';
}
export const adapter: EntityAdapter<INotification> = createEntityAdapter<INotification>({
  sortComparer: sortByUDate,
  selectId: selectId
});

export const initialState: State = adapter.getInitialState({
  selectedId: null,
  unreadFromAgg: 0,
  unreadCount: 0,
  unreadCountFromList: 0,
  error: ''
});

export const stateName = 'notification';

export const reducer = createReducer<State>(
  initialState,
  on(Actions.clearAllItems, (state) => {
    return adapter.removeAll(state);
  }),
  on(Actions.loadItemsSuccess, (state, { items }) => {
    return adapter.addMany(items, state);
  }),
  on(Actions.loadUnreadItemsSuccess, (state, { items }) => {
    return adapter.addMany(items, state);
  }),
  on(Actions.loadItemsAfterSuccess, (state, { items }) => {
    return adapter.addMany(items, state);
  }),
  on(Actions.updateItem, (state, { item }) => {
    return adapter.setOne(item, state);
  }),
  on(Actions.setItem, (state, action) => Object.assign({ ...state, selectedId: action.id })),
  on(Actions.setItemFromListPage,
    (state, action) => {
      return Object.assign({
        ...state, selectedId: action.id
      })
    })


);

// get the selectors
const {
  selectAll,
  selectEntities: selectItemEntities,
} = adapter.getSelectors();

export const getSelectedId = (state: State) => state.selectedId;
const getState = createFeatureSelector<State>(stateName);
export const selectAllItems = createSelector(getState, selectAll);
export const selectAllEntities = createSelector(getState, selectItemEntities);
export const selectCurrentId = createSelector(getState, getSelectedId);
export const selectCurrentItem = createSelector(selectAllEntities, selectCurrentId, (entities, id) => {
  if (id) return entities[id];
  return null;
});

export const selectEntity = (id: string) => createSelector(selectAllEntities, entities => entities[id]);

export const selectUnReadItems = createSelector(
  selectAllItems,
  (items) => items.filter(x => !x.read)
);

export const selectReadItems = createSelector(
  selectAllItems,
  (items) => items.filter(x => x.read)
);

export const getFromParams = createSelector(
  fromRouter.selectRouteParams,
  selectAllEntities,
  ({ id }, items) => {
    return items[id];
  }
);

export const getWithContentFromParams = createSelector(
  fromRouter.selectRouteParams,
  fromKeg.getKegsWithUnitBeverageAndLocation,
  selectAllEntities,
  ({ id }, kegs, items) => {
    const item = items[id];
    let retItem = { ...item } as INotificationVM;
    if (!item?.externalId) return retItem;
    const keg = kegs.find(x => x.id === item.externalId);
    retItem.keg = keg;

    // switch (item.type) {
    //   case notificationConsts.type_tap:
    //     const keg = kegs.find(x => x.id === item.externalId);
    //     retItem.keg = keg;
    //     break;
    //   case notificationConsts.alert:
    //     const keg2 = kegs.find(x => x.id === item.externalId);
    //     retItem.keg = keg2;
    //     break;

    // }

    return retItem;
  }
);
