import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BeverageService } from 'app/services/dal/beverage.service';
import { ToastService } from 'app/services/toast.service';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import * as BeverageActions from './actions'
import { addItem } from '../keg/actions';
import { Router } from '@angular/router';



@Injectable()
export class BeverageEffects {

  constructor(
    private router: Router,
    private actions$: Actions,
    private beverageService: BeverageService,
    private toastService: ToastService
  ) { }

  getBeverages$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(BeverageActions.loadItems),
        mergeMap((action) => this.beverageService.getItems(action.barId)
          .pipe(
            map(beverages => BeverageActions.loadItemsSuccess({ items: beverages })),
            catchError(error => of(BeverageActions.loadItemsFailure({ error })))
          )
        )
      );
  });


  setItem$ = createEffect(
    () => {
      return this.actions$
        .pipe(
          ofType(BeverageActions.setItem),
          tap(({ id }) => {
            this.router.navigate(['/inventory/beer/details', id]);
          }),
        )
    },
    { dispatch: false }
  );



  addBeverage$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(BeverageActions.addItem),
        mergeMap((action) => of(this.beverageService.create(action.item, action.id))
          .pipe(
            map(() => BeverageActions.addItemSuccess({ id: action.id })),
            catchError(error => of(BeverageActions.removeKegFromBeverageFailure({ error })))
          )
        )
      );
  });




  updateBeverage$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(BeverageActions.updateBeverage),
        mergeMap((action) => of(this.beverageService.updateItem(action.beverage))
          .pipe(
            map(() => {
              this.toastService.saveSuccess();
              return BeverageActions.updateBeverageSuccess();
            }),
            catchError(error => of(BeverageActions.removeKegFromBeverageFailure({ error })))
          )
        )
      );
  });

  softUpdate$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(BeverageActions.softUpdate),
        mergeMap((action) => from(this.beverageService.softUpdate(action.id, action.data, action.uid))
          .pipe(
            map(() => {
              this.toastService.saveSuccess();
              return BeverageActions.softUpdateSuccess({ id: action.id, data: action.data });
            }),
            catchError(error => of(BeverageActions.softUpdateFailure({ error })))
          )
        )
      );
  });

  delete$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(BeverageActions.deleteItem),
        mergeMap((action) => from(this.beverageService.delete(action.id))
          .pipe(
            map(() => {
              return BeverageActions.deleteItemSuccess();
            }),
            catchError(error => of(BeverageActions.softUpdateFailure({ error })))
          )
        )
      );
  });

  deleteSuccess$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(BeverageActions.deleteItemSuccess),
        tap(() => {
          this.toastService.deleteItemSuccess();
          this.router.navigateByUrl("/inventory/beer");
        }),
      );
  }, { dispatch: false });


  // getBeveragesSorted$ = createEffect(() => {
  //   return this.actions$
  //       .pipe(
  //           ofType(BeverageActions.loadItemsSorted),
  //           mergeMap((action) => this.beverageService.getItemsOrderBy(action.barId, action.sortOrder)
  //             .pipe(
  //               map(beverages => BeverageActions.loadItemsSuccess({ items: beverages })),
  //               catchError(error => of(BeverageActions.loadItemsFailure({ error })))
  //             )
  //           )
  //         );
  // });


}
