import { createAction, props } from '@ngrx/store';
import { ICheers } from 'app/models/cheers';

export const loadCheers = createAction(
  '[Home-Taps Page] Load Cheers'
  // ,
  // props<{ cheers: ICheers[]}>
);

export const upsertOneCheers = createAction(
  '[Home-Taps Page] Upsert Cheers',
  props<{ tap: ICheers }>()
);


 