import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { OnTapAdvancedService } from 'app/services/dal/on-tap-advanced.service';
import { OnTapBasicService } from 'app/services/dal/on-tap-basic.service';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as TapActions from './actions'
import { ToastService } from 'app/services/toast.service';



@Injectable()
export class Effects {

  constructor(
    private actions$: Actions,
    private toastService: ToastService,
    private onTapService: OnTapAdvancedService) { }

  getItems$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(TapActions.loadItems),
        mergeMap((action) => this.onTapService.getItems(action.barId)
          .pipe(
            map(items => TapActions.loadItemsSuccess({ items: items })),
            catchError(error => of(TapActions.loadItemsFailure({ error })))
          )
        )
      );
  });



  updateItem$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(TapActions.updateItem),
        mergeMap((action) => of(this.onTapService.update(action.item))
          .pipe(
            map(() => {
              this.toastService.saveSuccess();
              return TapActions.updateItemSuccess({ item: action.item });
            }),
            catchError(error => of(TapActions.updateItemFailure({ error })))
          )
        )
      );
  });



  add$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(TapActions.addItem),
        mergeMap((action) => of(this.onTapService.add(action.item, action.id))
          .pipe(
            map(() => {
              this.toastService.saveSuccess();
              return TapActions.addItemSuccess({ item: action.item });
            }),
            catchError(error => of(TapActions.addItemFailure({ error })))
          )
        )
      );
  });


  delete$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(TapActions.deleteItem),
        mergeMap((action) => of(this.onTapService.delete(action.id))
          .pipe(
            map(() => {
              this.toastService.deleteItemSuccess();
              return TapActions.deleteItemSuccess();
            }),
            catchError(error => of(TapActions.addItemFailure({ error })))
          )
        )
      );
  });


  softUpdate$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(TapActions.softUpdate),
        mergeMap((action) => from(this.onTapService.softUpdate(action.id, action.data, action.uid))
          .pipe(
            map(() => {
              this.toastService.saveSuccess();
              return TapActions.softUpdateSuccess({ id: action.id, data: action.data })
            }),
            catchError((error) => {
              return of(TapActions.softUpdateFailure({ error }))
            })
          )
        )
      );
  });


}
