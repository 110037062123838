import { routerReducer } from "@ngrx/router-store";
import { ActionReducerMap, MetaReducer } from "@ngrx/store";
import { environment } from "environments/environment";
import { clearStateMetaReducer } from "./clear-state.metareducer";

export interface State {

}

export const reducers: ActionReducerMap<State> = {
  router: routerReducer
};

export const metaReducers: MetaReducer<State>[] =
  !environment.production ? [clearStateMetaReducer] : [clearStateMetaReducer];
