import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap, filter } from 'rxjs/operators';
import * as Action from './actions'
import { ToastService } from '../../services/toast.service';
import { SubscriptionService } from 'app/services/dal/subscription.service';
import { Router } from '@angular/router';



@Injectable()
export class SubscriptionEffects {

  constructor(private router: Router, private actions$: Actions, private service: SubscriptionService, private toastService: ToastService) { }

  gotoSupbscription$ = createEffect(
    () => {
      return this.actions$
        .pipe(
          ofType(Action.gotoSubscribe),
          tap(() => {
            this.router.navigate(['subscribe']);
          }),
        )
    },
    { dispatch: false }
  );

  getSubscription$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(Action.loadItem),
        mergeMap((action) => this.service.getSubscription(action.barId)
          .pipe(
            map(item => Action.loadItemSuccess({ item: item })),
            catchError(error => of(Action.loadItemFailure({ error })))
          )
        )
      );
  });

  handleFetchError$ = createEffect(
    () => {
      return this.actions$
        .pipe(
          ofType(Action.loadItemFailure),
          tap((action) => {
            this.toastService.error(action.error)
          }),
        )
    },
    { dispatch: false }
  );



}
