import { Injectable } from '@angular/core';
import { first, interval, Observable, Subscription } from 'rxjs';
import { PlaatoExternalService } from './external/plaato-external.service';
import { ToastService } from './toast.service';
import { KegService } from './dal/keg.service';
import { AuthService } from './auth.service';
import { IKeg, IKegVM } from '../models/keg';
import { select, Store } from '@ngrx/store';

import { IStatistics } from '../models/statistics';
import { StatisticsService } from './dal/statistics.service';
import { SubscriptionService } from './dal/subscription.service';
import { UnitsHelper } from './units.helper';
import * as fromTap from 'app/state/tap';
import * as fromPlaatoStatus from 'app/state/plaato-status';
import { IPlaatoStatus } from 'app/models/plaato-status';
import { ITap, ITapVM } from 'app/models/tap';


@Injectable({
  providedIn: 'root'
})
export class PlaatoSchedulerService {

  private userId!: string;
  private barId!: string;
  private isPouring = false;

  private taps$: Observable<ITap[]>;
  private taps!: ITapVM[];

  constructor(
    private tapStore: Store<fromTap.State>,
    private plaatoStatusStore: Store<fromPlaatoStatus.State>,

    private authService: AuthService,
    private kegService: KegService,
    private plaatoExternalService: PlaatoExternalService,
    private toastService: ToastService,
    private statisticsService: StatisticsService,
    private subscriptionService: SubscriptionService,
    private unitsHelper: UnitsHelper
  ) {
    var user = authService.getUser();
    if (user != null && user.uid) {
      this.userId = user.uid;
    }

    this.taps$ = this.tapStore.select(fromTap.getItemsWithKegAndBeverage);

  }

  private isActive = false;
  private isPremium!: boolean;
  private isExecuting = false;
  private secondsCounter$!: Subscription;
  private secondsCounter!: Subscription;
  private unitType = 0;
  private tempUnit = '';

  async start() {

    this.barId = await this.authService.getUserBarId();



    this.kegService.getItems(this.barId).subscribe(kegs => {
      this.kegService.setStaticItems(kegs);
    });

    this.subscriptionService.hasPremiumObservable.subscribe(value => {
      this.isPremium = this.subscriptionService.hasPremium ?? false;

      if (this.isPremium) {
        this.getStaticItems();
      }
    });
    if (this.subscriptionService.hasPremium != null) {
      this.isPremium = this.subscriptionService.hasPremium;
      if (this.isPremium) {
        this.getStaticItems();
      }
    }

    const secondsCounter$ = interval(30000);
    this.secondsCounter = secondsCounter$.subscribe(n => {
      this.trigNow();
    });
  }

  async startWithBarId(barId: string) {

    //            this.getStaticItems();


    this.kegService.getItems(barId).subscribe(kegs => {
      this.kegService.setStaticItems(kegs);
    });
    this.subscriptionService.hasPremiumObservable.subscribe(value => {
      this.isPremium = this.subscriptionService.hasPremium ?? false;

      if (this.isPremium) {
        this.getStaticItems();
      }
    });

    if (this.subscriptionService.hasPremium != null) {
      this.isPremium = this.subscriptionService.hasPremium;
      if (this.isPremium) {
        this.getStaticItems();
      }
    }

    const secondsCounter$ = interval(30000);
    this.secondsCounter = secondsCounter$.subscribe(n => {
      console.info('barId START', barId);
      this.trigNow();
    });
  }

  trigNow() {
    // if (this.subscriptionService.hasPremium != null) {
    //   this.isPremium = this.subscriptionService.hasPremium;
    //   if (this.isPremium) {
    this.getStaticItems();
    //      }
    //  }



  }

  private getStaticItems() {
    const taps = this.taps$.pipe(first()).subscribe((taps: ITapVM[]) => {

      taps.forEach((tap, index) => {
        if (!!tap.plaato?.apiKey) {

          this.plaatoExternalService.getUnitType(tap.plaato?.apiKey).subscribe(async unitTypeResult => {
            const plaatoUnitType = unitTypeResult as any;
            this.GetPlaatoAmountLeft(tap, plaatoUnitType[0]);
          })


          // if (!this.tempUnit) {
          //   this.plaatoExternalService.getUnitType(keg.plaatoApiKey)
          //   .subscribe(async result => {
          //     this.unitType = +result[0];
          //     if (this.unitType === 2) {
          //       this.tempUnit = 'F';
          //     } else {
          //       this.tempUnit = 'C';
          //     }
          //   });
          // }

          // this.plaatoExternalService.getTempRaw(keg.plaatoApiKey)
          // .subscribe(async result => {
          //   const temp = Math.round(+result[0]);
          //   const tempString = temp + '°' + this.tempUnit;

          //   if (keg.temperature !== tempString) {
          //     keg.temperature = tempString;
          //     this.kegService.setStaticItem(keg, index);
          //     //await this.kegService.update(keg);
          //   }
          // });




        }
      });
    });

  }



  private GetPlaatoAmountLeft(tap: ITapVM, plaatoUnitType: any) {
    this.plaatoExternalService.getAmountLeft(tap.plaato?.apiKey ?? '')
      .subscribe(async result => {
        const plaatoAmountJson = JSON.parse(result);
        let plaatoAmount = 0;
        if (Array.isArray(plaatoAmountJson)) {
          plaatoAmount = +plaatoAmountJson[0];
        } else {
          plaatoAmount = +plaatoAmountJson;
        }
        const smallCheckAmount = plaatoUnitType === 1 ? 0.1 : 0.025; // 1dl or 0.025gal



        // Kun oppdater ved liten forskjell, fjerne ghosing
        if ((+(tap.keg?.amount ?? 0) !== +plaatoAmount && (+(tap.keg?.amount ?? 0) - plaatoAmount) > smallCheckAmount
          || (plaatoAmount - +(tap.keg?.amount ?? 0)) > smallCheckAmount)) {

          const plaatoStatus: IPlaatoStatus = {
            id: tap.plaatoId,
            barId: tap.barId,
            beverageName: tap.keg?.beverage?.name ?? '',
            amount: plaatoAmount,
            uDate: new Date(),
          };
          this.plaatoStatusStore.dispatch(fromPlaatoStatus.upsertItem({ item: plaatoStatus }));

          let newAmount = plaatoAmount

          if (plaatoUnitType != 1) {
            newAmount = this.unitsHelper.toUnit(plaatoAmount, 'gal', 'l', 1);
          }

          const updateKeg: Partial<IKeg> = {
            amount: newAmount
          }

          if (tap.kegId) {
            await this.kegService.softUpdate(tap.kegId, updateKeg, tap.uBy ?? '');
          }

          // const stat: IStatistics = {
          //   barId: this.barId,
          //   amount: +keg.amount - +result,
          //   name: keg.beverageName,
          //   bevStyle: 'Default-p',
          //   beverage: 'Beer',
          //   type: 'keg'
          // };
          // await this.statisticsService.add(stat);
        }
      });

  }




  stop() {
    if (this.secondsCounter) {
      this.secondsCounter.unsubscribe();
    }
  }

}
