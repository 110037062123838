import { Injectable } from '@angular/core';
import { FirestoreCrudService } from './crudService';
import { IStatistics } from '../../models/statistics';
import { dbConst } from './databaseConstants';
import { AuthService } from '../auth.service';
import { Firestore, collection, query, orderBy, limitToLast, where, collectionData, Timestamp } from '@angular/fire/firestore';
import { map, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {
  private crudService: FirestoreCrudService<IStatistics>;

  constructor(
    private firestore: Firestore,
    private authService: AuthService) {
    this.crudService = new FirestoreCrudService<IStatistics>(firestore, authService, dbConst.statistics);

  }

  async add(item: IStatistics) {
    if (item.amount > 0.1) {
      return await this.crudService.add(item);
    }
    return;
  }

  getLatestItems(barId: string, limit: number) {
    const listRef = collection(this.firestore, dbConst.statistics);
    const q = query(listRef, where('barId', '==', barId), orderBy('uDate'), limitToLast(limit));
    const items$ = collectionData(q, { idField: 'id' }) as Observable<IStatistics[]>;

    return collectionData(q, { idField: 'id' })
      .pipe(
        map(changes => {
          return changes.map(a => {
            const data = a as any;
            Object.keys(data).filter(key => Boolean(data[key]?.seconds))
              .forEach(key => {
                if (data[key].seconds) {
                  const date = new Timestamp(data[key].seconds, data[key].nanoseconds);
                  data[key] = date.toDate();
                }
              });
            Object.keys(data).filter(key => data[key] instanceof Timestamp)
              .forEach(key => data[key] = data[key].toDate())
            return data;
          });
        })
      ) as Observable<IStatistics[]>;
  }
  getLatestTapItems(tapId: string, limit: number) {
    const listRef = collection(this.firestore, dbConst.statistics);
    const q = query(listRef, where('tapId', '==', tapId), orderBy('uDate'), limitToLast(limit));
    const items$ = collectionData(q, { idField: 'id' }) as Observable<IStatistics[]>;

    return collectionData(q, { idField: 'id' })
      .pipe(
        map(changes => {
          return changes.map(a => {
            const data = a as any;
            Object.keys(data).filter(key => Boolean(data[key]?.seconds))
              .forEach(key => {
                if (data[key].seconds) {
                  const date = new Timestamp(data[key].seconds, data[key].nanoseconds);
                  data[key] = date.toDate();
                }
              });
            Object.keys(data).filter(key => data[key] instanceof Timestamp)
              .forEach(key => data[key] = data[key].toDate())
            return data;
          });
        })
      ) as Observable<IStatistics[]>;
  }
}
