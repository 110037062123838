import { Price } from '@invertase/firestore-stripe-payments';
import { createAction, props } from '@ngrx/store';

export const clearAllItems = createAction('[DalHelper] Clear All Stripe Prices');

export const loadItem = createAction('[Module Component] Load Stripe Price', props<{ id: string }>());
export const loadItemSuccess = createAction('[Font API] Load Load Stripe Price Success', props<{ item: Price }>());
export const loadItemFailure = createAction('[Font API] Load Load Stripe Price Failure', props<{ error: any }>());

export const loadItems = createAction('[Module Component] Load Stripe Prices', props<{ productId: string }>());
export const loadItemsSuccess = createAction('[Font API] Load Load Stripe Prices Success', props<{ items: Price[] }>());
export const loadItemsFailure = createAction('[Font API] Load Load Stripe Prices Failure', props<{ error: any }>());


