import { createEntityAdapter, Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { IBeverage, IBeverageVM } from 'app/models/beverage';
import * as fromActions from './actions';
import * as fromKegs from '../keg/reducer';
import { IKeg } from 'app/models/keg';


// export interface BeverageState {
//   beverages: IBeverage[];
//   error: string;
// }

export interface State extends EntityState<IBeverageVM> {
  // additional entity state properties
  selectedId: string | null;
  error: string;
}

export function sortByName(a: IBeverageVM, b: IBeverageVM): number {
  return a.name.localeCompare(b.name);
}
export function selectId(a: IBeverageVM): string {
  return a.id ?? '';
}
export const adapter: EntityAdapter<IBeverageVM> = createEntityAdapter<IBeverageVM>({
  sortComparer: false,
  selectId: selectId
});

export const initialState: State = adapter.getInitialState({
  selectedId: null,
  error: ''
});

// const initialBeverageState: BeverageState = {
//   beverages: [],
//   error: ''
// };

export const stateName = 'beverages';

// Reducers
export const reducer = createReducer<State>(
  initialState,
  on(fromActions.clearAllItems, (state) => {
    return adapter.removeAll(state);
  }),
  on(fromActions.loadItemsSuccess, (state, { items }) => {
    return adapter.setAll(items, state);
  }),
  on(fromActions.loadItemsFailure, (state, action) => {
    return {
      ...state,
      entities: {},
      error: action.error
    };
  }),
  on(fromActions.setItem, (state, action) => Object.assign({ ...state, selectedId: action.id })),
  on(fromActions.addItemSuccess, (state, action) => Object.assign({ ...state, selectedId: action.id })),
  on(
    fromActions.softUpdateFailure,
    fromActions.deleteItemFailure,
    (state, action): State => { return { ...state, error: action.error }; }),

);


// get the selectors
const {
  selectAll,
  selectEntities: selectItemEntities,
  selectIds: selectItemIds,
  selectTotal
} = adapter.getSelectors();


// ***** SELECTORS *******
export const getSelectedId = (state: State) => state.selectedId;
export const getState = createFeatureSelector<State>(stateName);
export const selectIds = createSelector(getState, selectItemIds);
export const selectAllEntities = createSelector(getState, selectItemEntities);
export const selectAllItems = createSelector(getState, selectAll);
//export const getAllEntities = createSelector(getState, selectEntities);

export const getCount = createSelector(getState, selectTotal);
export const selectCurrentId = createSelector(getState, getSelectedId);
export const selectCurrentItem = createSelector(selectAllEntities, selectCurrentId, (entities, id) => {
  if (id) return entities[id];
  return null;
});
export const selectEntity = (id: string) => createSelector(selectAllEntities, entities => entities[id]);

// export const getBeverageWithKegs = (id: string) => createSelector(
//   selectEntity(id),
//   fromKegs.getKegsWithUnit,
//   (item: IBeverageVM, kegs: IKeg[]) => {
//     if (item && kegs) {
//       const retItem = { ...item };
//       retItem.kegs = [];

//       kegs.forEach(keg => {
//         if (keg.beverageId === id) {
//           retItem.kegs.push(keg);
//         }
//       });
//       return retItem;
//     }


//     return item;

//   }
// );

// export const getBeveragesWithKegs = createSelector(
//   getAllItems,
//   fromKegs.getKegsWithUnit,
//   (items: IBeverageVM[], kegs: IKeg[]) => {
//     return items.map((item) => {
//       return {
//         ...item,
//         kegs: kegs.filter(x => x.beverageId === item.id)
//       }
//     })
//   }
// );
