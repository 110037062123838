import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BeverageService } from 'app/services/dal/beverage.service';
import { ToastService } from 'app/services/toast.service';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import * as fromActions from './actions'
import { BeerstyleService } from 'app/services/dal/beerstyle.service';



@Injectable()
export class BeerStyleEffects {

  constructor(
    private actions$: Actions,
    private service: BeerstyleService,
    private toastService: ToastService
  ) { }

  getItems$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(fromActions.loadItems),
        mergeMap((action) => this.service.getItems(action.barId)
          .pipe(
            map(items => fromActions.loadItemsSuccess({ items: items })),
            catchError(error => of(fromActions.loadItemsFailure({ error })))
          )
        )
      );
  });



  addItem$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(fromActions.addItem),
        mergeMap((action) => of(this.service.add(action.item, action.id))
          .pipe(
            map(() => {
              this.toastService.saveSuccess();
              return fromActions.addItemSuccess({ item: action.item });
            }),
            catchError(error => of(fromActions.addItemFailure({ error })))
          )
        )
      );
  });



  deleteItem$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(fromActions.deleteItem),
        mergeMap((action) => of(this.service.delete(action.id))
          .pipe(
            map(() => {
              this.toastService.deleteItemSuccess();
              return fromActions.deleteItemSuccess({ id: action.id });
            }),
            catchError(error => of(fromActions.addItemFailure({ error })))
          )
        )
      );
  });


  // removeKegFromBeverage$ = createEffect(() => {
  //   return this.actions$
  //       .pipe(
  //           ofType(BeverageActions.removeKegFromBeverage),
  //           mergeMap((action) => of(this.beverageService.removeKegFromBeverageWithoutUpdatingKeg(action.beverageId, action.kegId))
  //             .pipe(
  //               map(() => BeverageActions.removeKegFromBeverageSuccess()),
  //               catchError(error => of(BeverageActions.removeKegFromBeverageFailure({ error })))
  //             )
  //           )
  //         );
  // });


  // updateBeverage$ = createEffect(() => {
  //   return this.actions$
  //       .pipe(
  //           ofType(BeverageActions.updateBeverage),
  //           mergeMap((action) => of(this.beverageService.updateItem(action.beverage))
  //             .pipe(
  //               map(() => {
  //                 this.toastService.saveSettingsSuccess();
  //                 return BeverageActions.updateBeverageSuccess();
  //               }),
  //               catchError(error => of(BeverageActions.removeKegFromBeverageFailure({ error })))
  //             )
  //           )
  //         );
  // });


  // getBeveragesSorted$ = createEffect(() => {
  //   return this.actions$
  //       .pipe(
  //           ofType(BeverageActions.loadItemsSorted),
  //           mergeMap((action) => this.beverageService.getItemsOrderBy(action.barId, action.sortOrder)
  //             .pipe(
  //               map(beverages => BeverageActions.loadItemsSuccess({ items: beverages })),
  //               catchError(error => of(BeverageActions.loadItemsFailure({ error })))
  //             )
  //           )
  //         );
  // });


}
