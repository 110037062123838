import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as fromActions from './actions'
import { IOnTapTemplate } from 'app/models/on-tap-template';

export interface State extends EntityState<IOnTapTemplate> {
  // additional entity state properties
  selectedId: string | null;
  error: string;
}

export function sortByName(a: IOnTapTemplate, b: IOnTapTemplate): number {
  return (a.name ?? '').localeCompare(b.name ?? '');
}
export function selectId(a: IOnTapTemplate): string {
  return a.id ?? '';
}
export const adapter: EntityAdapter<IOnTapTemplate> = createEntityAdapter<IOnTapTemplate>({
  sortComparer: sortByName,
  selectId: selectId
});

export const initialState: State = adapter.getInitialState({
  selectedId: null,
  error: ''
});

// const initialBeverageState: BeverageState = {
//   beverages: [],
//   error: ''
// };

export const stateName = 'ontap-template';

// Reducers
export const reducer = createReducer<State>(
  initialState,
  on(fromActions.clearAllItems, (state) => {
    return adapter.removeAll(state);
  }),
  on(fromActions.loadItemsSuccess, (state, { a, b }) => {
    const result = a.concat(b);
    return adapter.setAll(result, state);
  }),
  on(fromActions.deleteItemSuccess, (state, { id }) => { return adapter.removeOne(id, state); }),
  on(fromActions.loadItemsFailure, (state: State, { error }): State => {
    return {
      ...state,
      error: error
    };
  }),
  on(fromActions.setItem, (state, action) => Object.assign({ ...state, selectedId: action.id }))



);


// get the selectors
const {
  selectAll,
  selectEntities: selectItemEntities,
  selectIds: selectItemIds,
  selectTotal
} = adapter.getSelectors();


// ***** SELECTORS *******
export const getSelectedId = (state: State) => state.selectedId;
export const getState = createFeatureSelector<State>(stateName);
export const selectIds = createSelector(getState, selectItemIds);
export const selectAllEntities = createSelector(getState, selectItemEntities);
export const selectAllItems = createSelector(getState, selectAll);
//export const getAllEntities = createSelector(getState, selectEntities);

export const getCount = createSelector(getState, selectTotal);
export const selectCurrentId = createSelector(getState, getSelectedId);
export const selectCurrentItem = createSelector(selectAllEntities, selectCurrentId, (entities, id) => {
  if (id) return entities[id];
  return null;
});
export const selectEntity = (id: string) => createSelector(selectAllEntities, entities => entities[id]);

export const selectGlobalTemplates = createSelector(
  selectAllItems,
  (items) => items.filter(x => !x.barId)
)

export const selectUserTemplates = createSelector(
  selectAllItems,
  (items) => items.filter(x => !!x.barId)
)

