import { createAction, props } from '@ngrx/store';
import { IKeg } from 'app/models/keg';
import { ITap, ITapAmount } from 'app/models/tap';
import { AnySrvRecord } from 'dns';

export const loadTapsAmount = createAction('[Taps Page] Load Taps Amount');

export const clearAllItems = createAction('[DalHelper] Clear All Taps');
export const loadItems = createAction('[Taps Page] Load Taps', props<{ barId: string }>());
export const loadItemsSuccess = createAction('[Taps API] Load Taps Success', props<{ items: ITap[] }>());
export const loadTapsFailure = createAction('[Taps API] Load Taps Failure', props<{ error: any }>());

export const updateTapsOrigAmount = createAction('[Taps API] Update Taps Amount', props<{ tapsAmount: ITapAmount }>());

export const addTapsOrigAmount = createAction('[Taps API] Add Taps Amount', props<{ tapsAmount: ITapAmount }>());

export const setItem = createAction('[Taps Detauk] Set selected id', props<{ id: string }>());



export const softUpdate = createAction('[Taps Detail Page] Soft Update Tap', props<{ id: string, data: any, uid: string }>());
export const softUpdateSuccess = createAction('[Taps API] Soft Update Tap Success', props<{ id: string, data: any }>());
export const softUpdateFailure = createAction('[Taps API] Soft Update Tap Failure', props<{ error: any }>());



export const updateWithKeg = createAction('[Taps Detail Page] Update Tap With Keg', props<{ tap: ITap, keg: IKeg }>());
export const updateWithKegSuccess = createAction('[Taps API] Update Tap With Keg Success', props<{ tap: ITap, keg: IKeg }>());
export const updateWithKegFailure = createAction('[Taps API] Update Tap With Keg Failure', props<{ error: any }>());



export const addItem = createAction('[Taps Detail Page] Add Tap ', props<{ item: ITap }>());
export const addItemSuccess = createAction('[Taps API] Add Tap Success');
export const addItemFailure = createAction('[Taps API] Add Tap Failure', props<{ error: any }>());

export const deleteItem = createAction('[Taps Detail Page] Delete Tap ', props<{ item: ITap }>());
export const deleteItemSuccess = createAction('[Taps API] Delete Tap Success');
export const deleteItemFailure = createAction('[Taps API] Delete Tap Failure', props<{ error: any }>());


