import { createAction, props } from '@ngrx/store';
import { IExternalApiSettings } from 'app/models/externalApiSettings';

export const loadItem = createAction(
  '[App Page] Load ExternalApiSettings',
  props<{ barId: string }>()
);

export const loadItemSuccess = createAction(
  '[Bar API] Load ExternalApiSettings Success',
  props<{ item: IExternalApiSettings }>()
);

export const loadItemFailure = createAction(
  '[Bar API] Load ExternalApiSettings Failure',
  props<{ error: any }>()
);


 