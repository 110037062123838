import { Injectable } from '@angular/core';
import { dbConst } from './databaseConstants';
import { IUser } from '../../models/user';
import { map } from 'rxjs/operators';
import { IBar } from '../../models/bar';
import { FirestoreCrudService } from './crudService';
import { AuthService } from '../auth.service';
import { Firestore } from '@angular/fire/firestore';
import { Notifications } from '../../models/untappdExternal';

@Injectable({
  providedIn: 'root'
})
export class BarService {

  private crudService: FirestoreCrudService<IBar>;

  bar!: IBar;

  constructor(
    private firestore: Firestore,
    private authService: AuthService) {
    this.crudService = new FirestoreCrudService<IBar>(firestore, authService, dbConst.bars);
  }

  getItem(barId: string) {
    return this.crudService.get(barId);
  }


  getBar(barId: string): Promise<IBar> {
    const promise = new Promise<IBar>(async (resolve, reject) => {
      if (this.bar) {
        resolve(this.bar);
      }

      this.bar = await this.crudService.getOnce(barId);

      resolve(this.bar);
    });
    return promise;
  }



  update(item: IBar) {
    return this.crudService.update(item);
  }

  async softUpdate(id: string, data: any, uid: string) {
    return await this.crudService.softUpdate(id, data, uid);
  }

  async setNotificationToken(bar: IBar, notificationToken: string, uid: string) {
    if (bar && bar.id) {
      let notifications = bar.notificationTokens ?? [];

      if (!notifications.includes(notificationToken)) {
        notifications.push(notificationToken);
        var data: Partial<IBar> = {
          notificationTokens: notifications
        };
        await this.crudService.softUpdate(bar.id, data, uid);
      }
    }
  }
}
