import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as fromActions from './actions'
import { Subscription } from '@invertase/firestore-stripe-payments';


export interface State extends EntityState<Subscription> {
  selectedId: string | null;
  loading: boolean;
  loadingManager: boolean;
  error: string;
}


export function selectId(a: Subscription): string {
  return a.id ?? '';
}
export const adapter: EntityAdapter<Subscription> = createEntityAdapter<Subscription>({
  selectId: selectId,
});

export const initialState: State = adapter.getInitialState({
  selectedId: null,
  loading: false,
  loadingManager: false,
  error: ''
});

export const stateName = 'stripeSubscription';

// Reducers
export const reducer = createReducer<State>(
  initialState,
  on(fromActions.clearAllItems, (state) => {
    return adapter.removeAll(state);
  }),
  on(fromActions.loadItems, (state) => {
    return {
      ...state,
      loading: true,
    }
  }),
  on(
    fromActions.loadItemsSuccess,
    fromActions.loadAllItemsSuccess,
    (state, { items }) => {
      const privateState = {
        ...state,
        loading: false,
      };

      return adapter.setAll(items, privateState);
    }),
  on(
    fromActions.loadItemFailure,
    fromActions.loadAllItemsFailure,
    (state, action) => {
      return {
        ...state,
        loading: false,
        entities: {},
        error: action.error
      };
    }),

  on(fromActions.setLoadingManager, (state, action) => {
    return {
      ...state,
      loadingManager: action.loading,
    };
  }),

);


// get the selectors
const {
  selectAll,
  selectEntities: selectItemEntities,
  selectIds: selectItemIds,
  selectTotal
} = adapter.getSelectors();


// ***** SELECTORS *******
export const getSelectedId = (state: State) => state.selectedId;
export const getState = createFeatureSelector<State>(stateName);
export const selectIds = createSelector(getState, selectItemIds);
export const selectEntities = createSelector(getState, selectItemEntities);
export const selectAllItems = createSelector(getState, selectAll);
//export const getAllEntities = createSelector(getState, selectEntities);

export const getCount = createSelector(getState, selectTotal);
export const selectCurrentId = createSelector(getState, getSelectedId);
export const selectCurrentItem = createSelector(selectEntities, selectCurrentId, (entities, id) => {
  if (id) return entities[id];
  return null;
});
export const selectEntity = (id: string) => createSelector(selectEntities, entities => entities[id]);

export const isLoading = createSelector(getState, (state) => state.loading);
export const isLoadingManager = createSelector(getState, (state) => state.loadingManager);



