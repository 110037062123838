import { Injectable } from '@angular/core';
import { dbConst } from './databaseConstants';
import { KegService } from './keg.service';
import { IOnTap2 } from '../../models/on-tap';
import { FirestoreCrudService } from './crudService';
import { Firestore } from '@angular/fire/firestore';
import { AuthService } from '../auth.service';
import { IonTabs } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class OnTapAdvancedService {

  private crudService: FirestoreCrudService<IOnTap2>;


  constructor(
    private firestore: Firestore,
    private authService: AuthService) {
    this.crudService = new FirestoreCrudService<IOnTap2>(firestore, authService, dbConst.ontap2s);
  }


  getNewId(): string {
    return this.crudService.getNewId();
  }




  async add(item: IOnTap2, id: string | undefined = undefined) {
    return await this.crudService.add(item, id);
  }


  async update(item: IOnTap2) {
    return await this.crudService.update(item);
  }

  async softUpdate(id: string, item: any, uid: string) {
    return await this.crudService.softUpdate(id, item, uid);
  }

  async delete(id: string) {
    return await this.crudService.delete(id);
  }

  getItem(id: string) {
    return this.crudService.get(id);
  }

  getItems(barId: string) {
    return this.crudService.getItemsOrderBy(barId, 'name', undefined);
  }

}
