import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { IKegtron, IKegtronVM } from 'app/models/kegtron';
import * as KegtronActions from './actions'
import * as fromTaps from 'app/state/tap';

export interface State extends EntityState<IKegtron> {
  // additional entity state properties
  selectedId: string | null;
  error: string;
}


export function sortByName(a: IKegtron, b: IKegtron): number {
  return a.name.localeCompare(b.name);
}
export function selectId(a: IKegtron): string {
  return a.id ?? '';
}
export const adapter: EntityAdapter<IKegtron> = createEntityAdapter<IKegtron>({
  sortComparer: sortByName,
  selectId: selectId
});

export const initialState: State = adapter.getInitialState({
  selectedId: null,
  error: ''
});

export const stateName = 'kegtron';

export const reducer = createReducer<State>(
  initialState,
  on(KegtronActions.clearAllItems, (state) => {
    return adapter.removeAll(state);
  }),
  on(KegtronActions.loadItemsSuccess, (state, { items }) => {
    return adapter.setAll(items, state);
  })
);

// get the selectors
const { selectAll, selectEntities } = adapter.getSelectors();

const selectOnTapBasicState = createFeatureSelector<State>(stateName);


export const getSelectedId = (state: State) => state.selectedId;
export const getState = createFeatureSelector<State>(stateName);
export const selectAllItems = createSelector(getState, selectAll);
export const selectAllEntities = createSelector(getState, selectEntities);
export const getCurrentId = createSelector(getState, getSelectedId);
export const getCurrentItem = createSelector(selectAllEntities, getCurrentId, (entities, id) => {
  if (id) return entities[id];
  return null;
});
export const getEntity = (id: string) => createSelector(selectAllEntities, entities => entities[id]);

