import { Injectable } from '@angular/core';
import { IKeg } from '../../models/keg';
import { dbConst } from './databaseConstants';
import { BeverageService } from './beverage.service';
import { FirestoreCrudService } from './crudService';
import { AuthService } from '../auth.service';
import { collection, collectionData, Firestore, query, Timestamp, where } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IStripeCustomer } from 'app/models/stripeCustomer';
import { addItem } from '../../state/notification/actions';

@Injectable({
  providedIn: 'root'
})
export class StripeCustomerService {

  private crudService: FirestoreCrudService<IStripeCustomer>;

  constructor(
    firestore: Firestore,
    authService: AuthService) {
    this.crudService = new FirestoreCrudService<IStripeCustomer>(firestore, authService, dbConst.stripeCustomers);
  }


  getNewId(): string {
    return this.crudService.getNewId();
  }



  getItem(id: string) {
    return this.crudService.get(id);
  }

  getItemOnce(id: string) {
    return this.crudService.getOnce(id);
  }

  getSubscriptions(stripeId: string) {

  }
  addItem(item: IStripeCustomer, uid: string) {
    return this.crudService.add(item, uid);
  }
}
