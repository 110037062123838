import { Action, ActionReducerMap, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { IServingSize } from 'app/models/servingsize';
import * as ServingSizeActions from './actions'

export interface ServingSizeState  {
  servingSizes: IServingSize[];
  error: string;
}

export const initialState: ServingSizeState = { 
  servingSizes: [],
  error: '',
};


export const stateName = 'servingSize';


//Selectors
const getServingSizesFeatureState = createFeatureSelector<ServingSizeState>(stateName);
export const getServingSizes = createSelector(
  getServingSizesFeatureState,
  state => state.servingSizes
);








const _servingSizeReducer = createReducer(
  initialState,
  // on(ServingSizeActions.addServingSize, (state, action): ServingSizeState => {
    
  // }),
  // on(ServingSizeActions.updateServingSize, (state, action): ServingSizeState => {}),
  // on(ServingSizeActions.removeServingSize, (state, action): ServingSizeState => {}),
  on(ServingSizeActions.loadServingSizesSuccess, (state, action) => {
    return {
      ...state,
      servingSizes: action.servingSizes,
      error: ''
    };
  }),
  on(ServingSizeActions.loadServingSizesFailure, (state, action) => {
    return {
      ...state,
      servingSizes: [],
      error: action.error
    };
  }),
); 

export function reducer(state: any, action: Action) {
  return _servingSizeReducer(state, action);
}



// export const selectServingSizeIds = createSelector(getServingSizeState, fromAdapter.selectServingSizeIds);
// export const selectServingSizeEntities = createSelector(getServingSizeState, fromAdapter.selectServingSizeEntities);
// export const selectAllServingSizes = createSelector(getServingSizeState, fromAdapter.selectAllServingSizes);
// export const servingSizesCount = createSelector(getServingSizeState, fromAdapter.servingSizeCount);
// export const selectCurrentServingSizeId = createSelector(getServingSizeState, getSelectedServingSizeId);


// export const selectCurrentArticle = createSelector(
//   selectServingSizeEntities,
//   selectCurrentServingSizeId,
//   (selectServingSizeEntities, servingSizeId) => selectServingSizeEntities[servingSizeId]
// ); 


// export const reducers: ActionReducerMap<State> = {
//   servingSizes: _servingSizeReducer
// }; 

// export const servingSizeReducer = createReducer<ServingSizeState>(
//   initialState,
//   on(ServingSizeActions.loadServingSizesSuccess, (state, action): ServingSizeState => {
//     return {
//       ...state,
//       servingSizes: action.servingSizes,
//       error: ''
//     };
//   }),

// );
