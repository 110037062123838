// import { Predicate } from '@angular/core';
// import { Update, EntityMapOne, EntityMap } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { IPlaatoStatus } from 'app/models/plaato-status';

export const setItem = createAction('[App Component] Set selected id', props<{ id: string }>());

export const addItem = createAction('[App Component] Add Plaato Status',props<{ item: IPlaatoStatus }>());
export const upsertItem = createAction('[App Component] Upsert Plaato Status',props<{ item: IPlaatoStatus }>());
export const deleteItem = createAction('[App Component] Delete Plaato Status',props<{ id: string }>());

// export const setPlaatosSuccess = createAction('[Plaato/API] Set Plaatos Success', props<{ items: IPlaato[] }>());
// export const addPlaatoSuccess = createAction('[Plaato/API] Add Plaato Success', props<{ item: IPlaato }>());
// export const setPlaatoSuccess = createAction('[Plaato/API] Set Plaato Success', props<{ item: IPlaato }>());
// export const upsertPlaatoSuccess = createAction('[Plaato/API] Upsert Plaato Success', props<{ item: IPlaato }>());
// export const addPlaatosSuccess = createAction('[Plaato/API] Add Plaatos Success', props<{ items: IPlaato[] }>());
// export const upsertPlaatosSuccess = createAction('[Plaato/API] Upsert Plaatos Success', props<{ items: IPlaato[] }>());
// export const updatePlaatoSuccess = createAction('[Plaato/API] Update Plaato Success', props<{ update: Update<IPlaato> }>());
// export const updatePlaatosSuccess = createAction('[Plaato/API] Update Plaatos Success', props<{ updates: Update<IPlaato>[] }>());
// export const mapPlaatoSuccess = createAction('[Plaato/API] Map Plaato Success', props<{ entityMap: EntityMapOne<IPlaato> }>());
// export const mapPlaatosSuccess = createAction('[Plaato/API] Map Plaatos Success', props<{ entityMap: EntityMap<IPlaato> }>());
// export const deletePlaatoSuccess = createAction('[Plaato/API] Delete Plaato Success', props<{ id: string }>());
// export const deletePlaatosSuccess = createAction('[Plaato/API] Delete Plaatos Success', props<{ ids: string[] }>());
// export const deletePlaatosByPredicateSuccess = createAction('[Plaato/API] Delete Plaatos By Predicate Success', props<{ predicate: Predicate<IPlaato> }>());
// export const clearPlaatosSuccess = createAction('[Plaato/API] Clear Plaatos Success');

 