import { Injectable } from '@angular/core';
import { IExternalApiSettings } from '../../models/externalApiSettings';
import { dbConst } from './databaseConstants';
import { map } from 'rxjs/internal/operators/map';
import { FirestoreCrudService } from './crudService';
import { Firestore } from '@angular/fire/firestore';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class ExternalApiSettingsService {

  private crudService: FirestoreCrudService<IExternalApiSettings>;

  constructor(
    private firestore: Firestore,
    private authService: AuthService) {
    this.crudService = new FirestoreCrudService<IExternalApiSettings>(firestore, authService, dbConst.externalApiSettings);
  }

  getItem(barId: string) {
    return this.crudService.get(barId);
  }


  update(item: IExternalApiSettings) {
    return this.crudService.update(item);
  }
  softUpdate(id: string, item: any) {
    return this.crudService.softUpdate(id, item);
  }




}
