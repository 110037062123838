import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CustomKegMonService } from 'app/services/dal/customKegMon.service';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as Action from './actions'



@Injectable()
export class CustomKegMonEffects {

  constructor(private actions$: Actions, private service: CustomKegMonService) { }

  getKegs$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(Action.loadItems),
        mergeMap((action) => this.service.getItems(action.barId)
          .pipe(
            map(items => Action.loadItemsSuccess({ items: items })),
            catchError(error => of(Action.loadItemsFailure({ error })))
          )
        )
      );
  });


}
