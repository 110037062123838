import { createAction, props } from '@ngrx/store';
import { IStripeProduct } from 'app/models/stripeProduct';

export const clearAllItems = createAction('[DalHelper] Clear All Stripe Products');

export const loadItem = createAction('[Module Component] Load Stripe Product', props<{ id: string }>());
export const loadItemSuccess = createAction('[Font API] Load Load Stripe Product Success', props<{ item: IStripeProduct }>());
export const loadItemFailure = createAction('[Font API] Load Load Stripe Product Failure', props<{ error: any }>());

export const loadItems = createAction('[Module Component] Load Stripe Products');
export const loadItemsSuccess = createAction('[Font API] Load Load Stripe Products Success', props<{ items: IStripeProduct[] }>());
export const loadItemsFailure = createAction('[Font API] Load Load Stripe Products Failure', props<{ error: any }>());


