import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { IPlaato, IPlaatoVM } from 'app/models/plaato';
import * as PlaatoActions from './actions'
import * as fromTaps from 'app/state/tap';

export interface State extends EntityState<IPlaato> {
  // additional entity state properties
  selectedId: string | null;
  error: string;
}

export function sortByName(a: IPlaato, b: IPlaato): number {
  return a.name.localeCompare(b.name);
}
export function selectId(a: IPlaato): string {
  //In this case this would be optional since primary key is id
  return a.id ?? '';
}
export const adapter: EntityAdapter<IPlaato> = createEntityAdapter<IPlaato>({
  sortComparer: sortByName,
  selectId: selectId
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  selectedId: null,
  error: ''
});

export const stateName = 'plaato';

export const reducer = createReducer<State>(
  initialState,
  on(PlaatoActions.clearAllItems, (state) => {
    return adapter.removeAll(state);
  }),
  on(PlaatoActions.loadPlaatosSuccess, (state, { items }) => {
    return adapter.setAll(items, state);
  }),
  on(PlaatoActions.updateItemSuccess, (state, { item }) => {
    return adapter.setOne(item, state);
  }),
  on(PlaatoActions.addItemSuccess, (state, { item }) => {
    return adapter.addOne(item, state);
  }),
  on(PlaatoActions.deleteItemSuccess, (state, { id }) => {
    return adapter.removeOne(id, state);
  }),

);


//export const getSelectedPlaatoId = (state: PlaatoState) => state.selectedPlaatoId;

// get the selectors
const {
  // selectIds,
  selectEntities,
  selectAll,
  // selectTotal,
} = adapter.getSelectors();


export const getSelectedId = (state: State) => state.selectedId;
export const getState = createFeatureSelector<State>(stateName);
export const selectAllItems = createSelector(getState, selectAll);
export const selectAllEntities = createSelector(getState, selectEntities);
export const getCurrentId = createSelector(getState, getSelectedId);
export const getCurrentItem = createSelector(selectAllEntities, getCurrentId, (entities, id) => {
  if (!!id) return entities[id];
  return null;
});
export const getEntity = (id: string) => createSelector(selectAllEntities, entities => entities[id]);

