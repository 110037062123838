import { Injectable } from '@angular/core';
import { dbConst } from './databaseConstants';
import { FirestoreCrudService } from './crudService';
import { AuthService } from '../auth.service';
import { IServingSize } from '../../models/servingsize';
import { Firestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ServingSizeService {
  private crudService: FirestoreCrudService<IServingSize>;
  constructor(
    private firestore: Firestore,
    private authService: AuthService) {
    this.crudService = new FirestoreCrudService<IServingSize>(firestore, authService, dbConst.servingSize);
  }

  public triggerActive = false;

  async add(item: IServingSize) {
    const newItem = await this.crudService.add(item);
    return newItem;
  }

  async update(item: IServingSize) {
    return await this.crudService.update(item);
  }

  async delete(id: string) {
    await this.crudService.delete(id);
  }

  getItem(id: string) {
    return this.crudService.get(id)
  }

  getItemOnce(id: string) {
    return this.crudService.getOnce(id)
  }

  async generateDefaultSizes(barId: string) {
    var servingSize: IServingSize = {
      name: '250ml',
      descr: '0.25L',
      barId: barId,
      amount: 0.25,
      icon: '/assets/icons/flat/beer/glass-size/glass-025l.svg',
      order: 1
    };
    await this.add(servingSize);

    servingSize = {
      name: '330ml',
      descr: '0.33L',
      barId: barId,
      amount: 0.33,
      icon: '/assets/icons/flat/beer/glass-size/glass-033l.svg',
      order: 2
    };
    await this.add(servingSize);

    servingSize = {
      name: '440ml',
      descr: '0.44L',
      barId: barId,
      amount: 0.44,
      icon: '/assets/icons/flat/beer/glass-size/glass-044l.svg',
      order: 3
    };
    await this.add(servingSize);


    servingSize = {
      name: '500ml',
      descr: '0.5L',
      barId: barId,
      amount: 0.5,
      icon: '/assets/icons/flat/beer/glass-size/glass-05l.svg',
      order: 4
    };
    await this.add(servingSize);



    servingSize = {
      name: 'One pint',
      descr: '0.551L',
      barId: barId,
      amount: 0.551,
      icon: '/assets/icons/flat/beer/glass-size/glass-051l.svg',
      order: 5
    };
    await this.add(servingSize);


    servingSize = {
      name: '750ml',
      descr: '0.75L',
      barId: barId,
      amount: 0.75,
      icon: '/assets/icons/flat/beer/glass-size/glass-075l.svg',
      order: 6
    };
    await this.add(servingSize);




  }

  getItems(barId: string) {
    return this.crudService.getItemsOrder(barId);
  }
}
