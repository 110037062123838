import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SecureInnerPagesGuard implements CanActivate {
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):
     | Observable<boolean | UrlTree>
     | Promise<boolean | UrlTree>
     | boolean | UrlTree {

      return new Promise(async (resolve, reject) => {
        try {
          const user = this.authService.getUser();
          if (user) {
            if (!user.emailVerified) {
              this.router.navigate(['verify-email']);
            } else {
              resolve(true);
            }
            // There is a logged in user.
          } else {
            console.error('ERROR');
            reject('No user logged in');
            // There is no user logged in.
            this.router.navigate(['login']);
          }
        } catch (error) {
          reject(error);
        }
      });


  }
  constructor(
    public authService: AuthService,
    public router: Router
  ) { }
}
