import { createAction, props } from "@ngrx/store";
import { IServingSize } from "app/models/servingsize";

export const loadServingSizes = createAction(
  "[Taps Page] Load ServingSizes",
  props<{ barId: string }>()
);

export const loadServingSizesSuccess = createAction(
  "[Taps API] Load ServingSizes Success",
  props<{ servingSizes: IServingSize[] }>()
);

export const loadServingSizesFailure = createAction(
  "[Taps API] Load ServingSizes Failure",
  props<{ error: any }>()
);

export const addServingSize = createAction(
  "[Settings-Taps Page] Add ServingSize",
  props<{ servingSize: IServingSize }>()
);

export const updateServingSize = createAction(
  "[Settings-Taps Page] Update ServingSize",
  props<{ servingSize: IServingSize }>()
);

export const removeServingSize = createAction(
  "[Settings-Taps Page] Remove ServingSize",
  props<{ id: string }>()
);

export const selectServingSize = createAction(
  "[Settings-Taps Page] Select ServingSize",
  props<{ id: string }>()
);
