import { createAction, props } from '@ngrx/store';
import { IStatistics } from 'app/models/statistics';

//export const loadStatistics = createAction('[Bar Page] Load Stats', props<{ barId: string }>());
//export const loadStatisticsSuccess = createAction('[Stats API] Load Stats Limit Success', props<{ statistics: IStatistics[] }>());
export const loadStatisticsFailure = createAction('[Stats API] Load Stats Failure', props<{ error: any }>());


export const clearStatisticsForHomepage = createAction('[DalHelper] Clear All Stats For Homepage');

export const loadStatisticsForHomepage = createAction('[Favorites Page] Load Stats For Homepage', props<{ barId: string, limit: number }>());
export const loadStatisticsForHomepageSuccess = createAction('[Stats API] Load Stats For Homepage Success', props<{ items: IStatistics[] }>());

export const loadStatisticsForTap = createAction('[Tap Detail Page] Load Tap Stats', props<{ tapId: string, limit: number }>());
export const loadStatisticsForTapSuccess = createAction('[Stats API] Load Stats Tap Success', props<{ items: IStatistics[] }>());
export const loadStatisticsForTapFailure = createAction('[Stats API] Load Tap Stats Failure', props<{ error: any }>());

export const addItem = createAction('[Home Page] Add Stats', props<{ item: IStatistics }>());
export const addItemSuccess = createAction('[Plaato API] Add Stats Success', props<{ item: IStatistics }>());
export const addItemFailure = createAction('[Plaato API] Add Stats Failure', props<{ error: any }>());




