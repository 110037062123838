import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { KegtronService } from 'app/services/dal/kegtron.service';
import { OnTapBasicService } from 'app/services/dal/on-tap-basic.service';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as KegtronActions from './actions'



@Injectable()
export class KegtronEffects {

  constructor(private actions$: Actions, private kegtronService: KegtronService) { }

  getKegs$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(KegtronActions.loadItems),
        mergeMap((action) => this.kegtronService.getItems(action.barId)
          .pipe(
            map(items => KegtronActions.loadItemsSuccess({ items: items })),
            catchError(error => of(KegtronActions.loadItemsFailure({ error })))
          )
        )
      );
  });


}
