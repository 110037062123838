import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { IBarSettings } from 'app/models/barSettings';
import * as fromActions from './actions'

// export interface BeverageState {
//   beverages: IBeverage[];
//   error: string;
// }

export interface State extends EntityState<IBarSettings> {
  // additional entity state properties
  selectedId: string | null;
  error: string;
}

export function selectId(a: IBarSettings): string {
  return a.id ?? '';
}
export const adapter: EntityAdapter<IBarSettings> = createEntityAdapter<IBarSettings>({
  sortComparer: false,
  selectId: selectId
});

export const initialState: State = adapter.getInitialState({
  selectedId: null,
  error: ''
});

// const initialBeverageState: BeverageState = {
//   beverages: [],
//   error: ''
// };

export const stateName = 'barsettings';

// Reducers
export const reducer = createReducer<State>(
  initialState,
  on(fromActions.clearAllItems, (state) => {
    return adapter.removeAll(state);
  }),
  on(fromActions.loadItemsSuccess, (state, { items }) => {
    return adapter.setAll(items, state);
  }),
  on(fromActions.loadItemsFailure, (state, action) => {
    return {
      ...state,
      entities: {},
      error: action.error
    };
  }),


  on(fromActions.loadItemSuccess, (state, { item }) => {
    return adapter.setOne(item, state);
  }),
  on(fromActions.loadItemsFailure, (state, action) => {
    return {
      ...state,
      entities: {},
      error: action.error
    };
  }),

  on(fromActions.setItem, (state, action) => Object.assign({ ...state, selectedId: action.id })),

  on(fromActions.updateItemSuccess, (state, action) => {
    return adapter.updateOne(action.update, state);
  }),
  on(fromActions.updateItemFailure, (state, action) => {
    return {
      ...state,
      error: action.error
    };
  }),


);


// get the selectors
const {
  selectAll,
  selectEntities: selectItemEntities,
  selectIds: selectItemIds,
  selectTotal
} = adapter.getSelectors();


// ***** SELECTORS *******
export const getSelectedId = (state: State) => state.selectedId;
export const getState = createFeatureSelector<State>(stateName);
export const selectIds = createSelector(getState, selectItemIds);
export const selectEntities = createSelector(getState, selectItemEntities);
export const getAllItems = createSelector(getState, selectAll);
export const getCount = createSelector(getState, selectTotal);
export const selectCurrentId = createSelector(getState, getSelectedId);
export const selectCurrentItem = createSelector(selectEntities, selectCurrentId, (entities, id) => {
  if (id) return entities[id];
  return null;
});

