import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthService } from 'app/services/auth.service';
import { SubscriptionService } from 'app/services/dal/subscription.service';
import { from, of } from 'rxjs';
import { catchError, mergeMap, filter, map, switchMap, tap } from 'rxjs/operators';
import * as UserActions from './actions'
import { UserService } from 'app/services/dal/user.service';
import { Store } from '@ngrx/store';
import * as fromSubscription from 'app/state/subscription';
import { ISubscription } from 'app/models/subscription';

@Injectable()
export class UserEffects {

  constructor(
    private store: Store,
    private actions$: Actions,
    private authService: AuthService,
    private userService: UserService,
    private subscriptionService: SubscriptionService) { }

  login$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(UserActions.loadUser),
        mergeMap(() => this.authService.getUserFromAuthState()
          .pipe(
            map(user => {
              const retUser = JSON.parse(JSON.stringify(user));
              return UserActions.loadUserSuccess({ user: retUser })
            }),
            catchError(error =>
              of(
                UserActions.loadUserFailure({ error })
              ))
          )
        )
      );
  });

  barId$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(UserActions.loadBarId),
        mergeMap(() => this.authService.getBarId()
          .pipe(
            filter(barId => Boolean(barId)),
            map((barId: string) => UserActions.loadBarIdSuccess({ barId })),
            catchError(error => {
              console.error('error', error);

              return of(UserActions.loadBarIdFailure({ error }))
            })
          )
        )
      );
  });

  subs$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(UserActions.loadSubscriptions),
        mergeMap((action) => from(this.authService.getUserSubscriptions())
          .pipe(
            map((subs: any[]) => UserActions.loadSubscriptionsSuccess({ barId: action.barId, subs })),
            catchError(error => {
              console.error('error', error);

              return of(UserActions.loadBarIdFailure({ error }))
            })
          )
        )
      );
  });

  // subsSuccess$ = createEffect(() => {
  //   return this.actions$
  //     .pipe(
  //       ofType(UserActions.loadSubscriptionsSuccess),
  //       tap((action) => {
  //         //   if (action.barId) {
  //         //     const sub1 = action.subs.find(sub => sub.barId === action.barId);
  //         //     const subs: ISubscription = {
  //         //       barId: action.barId,
  //         //       billingPeriodEnds: new Date(sub1.expDate)
  //         //     };
  //         //     this.store.dispatch(fromSubscription.loadItemSuccess(subs));
  //         //   }

  //       })
  //     );
  // });

  barIdfromfunction$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(UserActions.loadBarIdByFunction),
        mergeMap(() => from(this.authService.getBarIdFromFunction())
          .pipe(
            map(barId => UserActions.loadBarIdSuccess({ barId: barId.toString() })),
            catchError(error => {
              console.error('error', error);
              return of(UserActions.loadBarIdFailure({ error }))
            })
          )
        )
      );
  });

  barId2$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(UserActions.loadBarIdFromUserFirestore),
        mergeMap((action) => this.userService.getItem(action.uid)
          .pipe(
            filter(user => Boolean(user)),
            map((user) => UserActions.loadBarIdSuccess({ barId: user?.securityClaims.barOwner[0] })),
            catchError(error => {
              console.error('error', error);

              return of(UserActions.loadBarIdFailure({ error }))
            })
          )
        )
      );
  });
}
