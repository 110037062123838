import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { IStatistics } from 'app/models/statistics';
import * as StatisticActions from './actions'
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

export interface State extends EntityState<IStatistics> {
  // additional entity state properties
  selectedStatisics: IStatistics[];
  selectedId: string | null;
  error: string;
}
export function sortByName(a: IStatistics, b: IStatistics): number {
  return a.name.localeCompare(b.name);
}

export function sortByDate(a: IStatistics, b: IStatistics): number {
  const aTime = a.uDate != null ? new Date(a.uDate).getTime() : 0;
  const bTime = b.uDate != null ? new Date(b.uDate).getTime() : 0;

  return aTime.toString().localeCompare(bTime.toString());
}


export function getTime(date?: Date) {
  return date != null ? date.getTime() : 0;
}


export function sortByDueDate(items: IStatistics[]): IStatistics[] {
  return items.sort((a: IStatistics, b: IStatistics) => {
    return getTime(a.uDate) - getTime(b.uDate);
  });
}

export function selectId(a: IStatistics): string {
  //In this case this would be optional since primary key is id
  return a.id ?? '';
}
export const adapter: EntityAdapter<IStatistics> = createEntityAdapter<IStatistics>({
  sortComparer: sortByDate,
  selectId: selectId
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  selectedStatisics: [],
  selectedId: null,
  error: ''
});
// export interface StatisticState {
//   statistics: IStatistics[];
//   error: string;
// }
// const initialState: StatisticState = {
//   statistics: [],
//   error: ''
// };

export const stateName = 'statistics';


export const reducer = createReducer<State>(
  initialState,
  on(StatisticActions.clearStatisticsForHomepage, (state) => {
    return adapter.removeAll(state);
  }),
  on(StatisticActions.loadStatisticsForHomepageSuccess, (state, { items }) => {
    return adapter.upsertMany(items, state);
  }),
  on(StatisticActions.loadStatisticsForTapSuccess, (state, { items }) => {
    return adapter.upsertMany(items, state);
  }),
  on(StatisticActions.addItemSuccess, (state, { item }) => {
    return adapter.addOne(item, state);
  }),

);


const {
  // selectIds,
  selectEntities,
  selectAll,
  // selectTotal,
} = adapter.getSelectors();


export const getSelectedId = (state: State) => state.selectedId;
export const getState = createFeatureSelector<State>(stateName);
export const getAllItems = createSelector(getState, selectAll);
export const getEntities = createSelector(getState, selectEntities);
export const getCurrentId = createSelector(getState, getSelectedId);
export const getCurrentItem = createSelector(getEntities, getCurrentId, (entities, id) => {
  if (id) return entities[id];
  return null;
});
export const getEntity = (id: string) => createSelector(getEntities, entities => entities[id]);


export const getItemsForHomepage = (limit: number) => createSelector(
  getAllItems,
  (item: IStatistics[]) => {
    return item.slice(0, limit);
  }
);


export const getItemsForTap = (tapId: string, limit: number) => createSelector(
  getAllItems,
  (items: IStatistics[]) => {
    const retItems = items.filter(x => x.tapId === tapId);
    //    const sortedItems = sortByDueDate(retItems);
    return retItems.slice(0, limit);

  }
);


