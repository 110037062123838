
import { combineLatest, EMPTY, lastValueFrom, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IEntity } from '../../models/entity';
import { AuthService } from '../auth.service';
import { Firestore, collection, collectionData, doc, docData, addDoc, deleteDoc, updateDoc, query, orderBy, limitToLast, where, limit, setDoc, getDoc, Timestamp, getDocs } from '@angular/fire/firestore';
import { OrderByDirection, QueryConstraint, QueryFieldFilterConstraint, QueryOrderByConstraint, startAfter } from 'firebase/firestore';



// We need a function that will turn our JS Objects into an Object
// that Firestore can work with
function firebaseSerialize<T>(object: T) {
  return JSON.parse(JSON.stringify(object));
}




// // We need a base Entity interface that our models will extend
// export interface Entity {
//   id ? : string; // Optional for new Entities
// }

export class FirestoreCrudService<T extends IEntity> {
  // Reference to the Collection in Firestore
  private _collectionName: string;

  /* We need to ask for the AngularFirestore Injectable
   * and a Collection Name to use in Firestore
   */
  constructor(
    private firestore: Firestore,
    private authService: AuthService,
    public collectionName: string
  ) {
    // We then create the reference to this Collection
    //this.itemsCollection = collection(firestore, collectionName);
    this._collectionName = collectionName;
  }


  /**
   * This function generates a new ID for a document in a Firestore collection.
   * @returns The function `getNewId()` returns a new unique ID generated by Firestore for a new
   * document that can be added to the specified collection.
   */
  getNewId() {
    const collRef = collection(this.firestore, this._collectionName);
    const newDocRef = doc(collRef);
    return newDocRef.id;
  }


  /**
   * We look for the Entity we want to add as well
   * as an Optional Id, which will allow us to set
   * the Entity into a specific Document in the Collection
   */
  async add(entity: T, id: string | undefined = undefined): Promise<T> {
    const user = await this.authService.getUser();
    entity = {
      ...entity,
      uBy: user?.uid,
      uDate: new Date()
    };

    const collRef = collection(this.firestore, this._collectionName);
    if (id) {
      const document = doc(this.firestore, this._collectionName, id);
      return setDoc(document, entity).then(() => {
        return this.getOnce(id);
      });

    } else {
      return addDoc(collRef, entity).then(async item => {
        return this.getOnce(item.id);
      });

    }


  }


  // Our get method will fetch a single Entity by it's Document ID
  get(id: string): Observable<T> {
    const collectionId = this.getItemPath(id);

    const docRef = doc(this.firestore, collectionId);
    const docDataContent = docData(docRef, { idField: 'id' })
      .pipe(
        map((value: any) => {
          if (!!value) {
            const data = value as any;
            Object.keys(data).filter(key => Boolean(data[key]?.seconds))
              .forEach(key => {
                if (data[key].seconds) {
                  const date = new Timestamp(data[key].seconds, data[key].nanoseconds);
                  data[key] = date.toDate();
                }
              });
            Object.keys(data).filter(key => data[key] instanceof Timestamp)
              .forEach(key => data[key] = data[key].toDate())
            return data;
          }
        }),
        catchError((error: unknown) => {
          console.error(`stream caught [${collectionId}] : ${error}`);
          return of(EMPTY);
        })
      );
    return docDataContent;
  }



  // Our get method will fetch a single Entity by it's Document ID
  async getOnce(id: string): Promise<T> {
    const collectionId = this.getItemPath(id);
    const docRef = doc(this.firestore, collectionId);
    const docSnap = getDoc(docRef);
    const result = (await docSnap).data() as T;
    if (result) {
      result.id = id;
    }
    return result;
    //    return (await docSnap).data() as T;
  }

  // Our list method will get all the Entities in the Collection
  list(): Observable<T[]> {
    const listRef = collection(this.firestore, this._collectionName);
    return collectionData(listRef, { idField: 'id' })
      .pipe(
        map(changes => {
          return changes.map(a => {
            const data = a as any;
            Object.keys(data).filter(key => Boolean(data[key]?.seconds))
              .forEach(key => {
                if (data[key].seconds) {
                  const date = new Timestamp(data[key].seconds, data[key].nanoseconds);
                  data[key] = date.toDate();
                }
              });
            Object.keys(data).filter(key => data[key] instanceof Timestamp)
              .forEach(key => data[key] = data[key].toDate())
            return data;
          });
        })
      ) as Observable<T[]>;
  }


  // Our list method will get all the Entities in the Collection
  getGeneralItems(limit?: number): Observable<T[]> {

    limit = limit ? limit : 1000;

    const listRef = collection(this.firestore, this._collectionName);
    const q = query(listRef, orderBy('id'), limitToLast(limit));
    return collectionData(q, { idField: 'id' })
      .pipe(
        map(changes => {
          return changes.map(a => {
            const data = a as any;
            Object.keys(data).filter(key => Boolean(data[key]?.seconds))
              .forEach(key => {
                if (data[key].seconds) {
                  const date = new Timestamp(data[key].seconds, data[key].nanoseconds);
                  data[key] = date.toDate();
                }
              });
            Object.keys(data).filter(key => data[key] instanceof Timestamp)
              .forEach(key => data[key] = data[key].toDate())
            return data;
          });
        })
      ) as Observable<T[]>;
  }

  // Our list method will get all the Entities in the Collection
  getGeneralItemsPromise(orderby: string): Promise<T[] | undefined> {

    const listRef = collection(this.firestore, this._collectionName);
    const q = query(listRef, orderBy(orderby));

    var sub = collectionData(q, { idField: 'id' })
      .pipe(
        map(changes => {
          return changes.map(a => {
            const data = a as any;
            Object.keys(data).filter(key => Boolean(data[key]?.seconds))
              .forEach(key => {
                if (data[key].seconds) {
                  const date = new Timestamp(data[key].seconds, data[key].nanoseconds);
                  data[key] = date.toDate();
                }
              });
            Object.keys(data).filter(key => data[key] instanceof Timestamp)
              .forEach(key => data[key] = data[key].toDate())
            return data;
          });
        })
      ) as Observable<T[]>;

    return sub.toPromise();
  }



  // Our list method will get all the Entities in the Collection
  getItems(barId: string, limitItems?: number): Observable<T[]> {
    limitItems = limitItems ? limitItems : 1000;
    const listRef = collection(this.firestore, this._collectionName);
    const q = query(listRef, where('barId', '==', barId), limit(limitItems));
    return collectionData(q, { idField: 'id' })
      .pipe(
        map(changes => {
          return changes.map(a => {
            const data = a as any;
            Object.keys(data).filter(key => Boolean(data[key]?.seconds))
              .forEach(key => {
                if (data[key].seconds) {
                  const date = new Timestamp(data[key].seconds, data[key].nanoseconds);
                  data[key] = date.toDate();
                }
              });

            Object.keys(data).filter(key => data[key] instanceof Timestamp)
              .forEach(key => data[key] = data[key].toDate())
            return data;
          });
        })
      ) as Observable<T[]>;
  }


  // Our list method will get all the Entities in the Collection
  getItemsOrder(barId: string, limitItems?: number): Observable<T[]> {
    limitItems = limitItems ? limitItems : 1000;
    const listRef = collection(this.firestore, this._collectionName);
    const q = query(listRef, where('barId', '==', barId), orderBy('order'), limit(limitItems));
    return collectionData(q, { idField: 'id' })
      .pipe(
        map(changes => {
          return changes.map(a => {
            const data = a as any;
            Object.keys(data).filter(key => data[key] instanceof Timestamp)
              .forEach(key => data[key] = data[key].toDate())
            return data;
          });
        })
      ) as Observable<T[]>;
  }


  mapFieldWithDate(q: any) {
    return collectionData(q, { idField: 'id' })
      .pipe(
        map(changes => {
          return changes.map(a => {
            const data = a as any;
            Object.keys(data).filter(key => Boolean(data[key]?.seconds))
              .forEach(key => {
                if (data[key].seconds) {
                  const date = new Timestamp(data[key].seconds, data[key].nanoseconds);
                  data[key] = date.toDate();
                }
              });
            Object.keys(data).filter(key => data[key] instanceof Timestamp)
              .forEach(key => data[key] = data[key].toDate())
            return data;
          });
        })
      ) as Observable<T[]>;
  }


  // Our list method will get all the Entities in the Collection
  getItemsDynamic(wheres: QueryFieldFilterConstraint[], sortOrders: QueryOrderByConstraint[], limitItems: QueryConstraint[]): Observable<T[]> {

    const listRef = collection(this.firestore, this._collectionName);

    const q = query(listRef,
      ...wheres,
      ...sortOrders,
      ...limitItems);
    return collectionData(q, { idField: 'id' })
      .pipe(
        map(changes => {
          return changes.map(a => {
            const data = a as any;
            Object.keys(data).filter(key => Boolean(data[key]?.seconds))
              .forEach(key => {
                if (data[key].seconds) {
                  const date = new Timestamp(data[key].seconds, data[key].nanoseconds);
                  data[key] = date.toDate();
                }
              });
            Object.keys(data).filter(key => data[key] instanceof Timestamp)
              .forEach(key => data[key] = data[key].toDate())
            return data;
          });
        })
      ) as Observable<T[]>;

  }

  // Our list method will get all the Entities in the Collection
  getItemsOrderBy(barId: string, sortOrder: string, order?: OrderByDirection, limitItems?: number): Observable<T[]> {

    limitItems = limitItems ?? 1000;

    order = order ?? 'asc';

    const listRef = collection(this.firestore, this._collectionName);
    const q = query(listRef, where('barId', '==', barId), orderBy(sortOrder, order), limit(limitItems));
    return collectionData(q, { idField: 'id' })
      .pipe(
        map(changes => {
          return changes.map(a => {
            const data = a as any;
            Object.keys(data).filter(key => Boolean(data[key]?.seconds))
              .forEach(key => {
                if (data[key].seconds) {
                  const date = new Timestamp(data[key].seconds, data[key].nanoseconds);
                  data[key] = date.toDate();
                }
              });
            Object.keys(data).filter(key => data[key] instanceof Timestamp)
              .forEach(key => data[key] = data[key].toDate())
            return data;
          });
        })
      ) as Observable<T[]>;

  }
  // Our list method will get all the Entities in the Collection
  getItemsOrderByDesc(barId: string, sortOrder: string, limitItems?: number): Observable<T[]> {

    limitItems = limitItems ? limitItems : 1000;

    const listRef = collection(this.firestore, this._collectionName);
    const q = query(listRef, where('barId', '==', barId), orderBy(sortOrder, 'desc'), limit(limitItems));
    return collectionData(q, { idField: 'id' })
      .pipe(
        map(changes => {
          return changes.map(a => {
            const data = a as any;
            Object.keys(data).filter(key => Boolean(data[key]?.seconds))
              .forEach(key => {
                if (data[key].seconds) {
                  const date = new Timestamp(data[key].seconds, data[key].nanoseconds);
                  data[key] = date.toDate();
                }
              });
            Object.keys(data).filter(key => data[key] instanceof Timestamp)
              .forEach(key => data[key] = data[key].toDate())
            return data;
          });
        })
      ) as Observable<T[]>;

  }

  // Our list method will get all the Entities in the Collection
  async getItemsOrderByWithTakeAfter(barId: string, sortOrder: string, limitItems: number, startAfterId: string): Promise<T[]> {

    const listRef = collection(this.firestore, this._collectionName);
    const collectionId = this.getItemPath(startAfterId);
    const startAfterRef = doc(this.firestore, collectionId);
    const startAfterSnap = await getDoc(startAfterRef);
    const q = query(listRef, where('barId', '==', barId), orderBy(sortOrder), limit(limitItems), startAfter(startAfterSnap));
    const sub = collectionData(q, { idField: 'id' })
      .pipe(
        map(changes => {
          return changes.map(a => {
            const data = a as any;
            Object.keys(data).filter(key => Boolean(data[key]?.seconds))
              .forEach(key => {
                if (data[key].seconds) {
                  const date = new Timestamp(data[key].seconds, data[key].nanoseconds);
                  data[key] = date.toDate();
                }
              });
            Object.keys(data).filter(key => data[key] instanceof Timestamp)
              .forEach(key => data[key] = data[key].toDate())
            return data;
          });
        })
      ) as Observable<T[]>;

    return await lastValueFrom(sub);

  }

  // Our list method will get all the Entities in the Collection
  getGeneralItemsOrderBy(sortOrder: string, limitItems?: number): Observable<T[]> {

    limitItems = limitItems ? limitItems : 1000;

    const listRef = collection(this.firestore, this._collectionName);
    const q = query(listRef, orderBy(sortOrder), limit(limitItems));
    return collectionData(q, { idField: 'id' })
      .pipe(
        map(changes => {
          return changes.map(a => {
            const data = a as any;
            Object.keys(data).filter(key => Boolean(data[key]?.seconds))
              .forEach(key => {
                if (data[key].seconds) {
                  const date = new Timestamp(data[key].seconds, data[key].nanoseconds);
                  data[key] = date.toDate();
                }
              });
            Object.keys(data).filter(key => data[key] instanceof Timestamp)
              .forEach(key => data[key] = data[key].toDate())
            return data;
          });
        })
      ) as Observable<T[]>;
  }


  // Our list method will get all the Entities in the Collection
  getBarAndGlobalItemsOrderBy(barId: string, orderBy: string): Observable<{ a: T[], b: T[] }> {


    const collection1 = this.getItemsOnce('', orderBy, 1000);
    const collection2 = this.getItemsOrderBy(barId, orderBy);

    const xx = combineLatest([collection1, collection2]).pipe(
      map(([a$, b$]) => ({
        a: a$,
        b: b$
      }))
    );
    return xx;
  }



  // Our list method will get all the Entities in the Collection
  async getItemsOnce(barId: string, orderby: string, limitItems: number): Promise<T[]> {

    limitItems = limitItems ? limitItems : 1000;


    const listRef = collection(this.firestore, this._collectionName);
    const q = query(listRef, where('barId', '==', barId), orderBy(orderby), limit(limitItems));

    const querySnap = await getDocs(q);

    const items: T[] = [];

    querySnap.forEach((doc) => {
      const item = doc.data();
      if (item) {
        item['id'] = doc.id;
        items.push(item as T);
      }
    });

    return items;

  }

  // Our list method will get all the Entities in the Collection
  // getItemsPromise(barId, orderby: string): Promise<T[]> {
  //   return this.getItemsOrderBy(barId, orderby).toPromise();
  // }

  // Our Update method takes the full updated Entity
  // Including it's ID property which it will use to find the
  // Document. This is a Hard Update.
  async update(entity: T): Promise<void> {
    var docPath = this.getItemPath(entity.id ?? '');
    const docRef = doc(this.firestore, docPath);
    return setDoc(docRef, entity);
  }

  // Our Update method takes the full updated Entity
  // Including it's ID property which it will use to find the
  // Document. This is a Hard Update.
  async softUpdate(id: string, entity: any, uid: string | null = null,): Promise<void> {
    //const user = await this.authService.getUser();
    var docPath = this.getItemPath(id);
    const docRef = doc(this.firestore, docPath);

    let updateEntity = {};
    if (!!uid) {
      updateEntity = {
        ...entity,
        uBy: uid,
        uDate: new Date(),
      };

    } else {
      updateEntity = {
        ...entity,
        uDate: new Date(),
      };
    }

    return updateDoc(docRef, updateEntity);
  }

  delete(id: string): Promise<void> {

    var docPath = this.getItemPath(id);
    const docRef = doc(this.firestore, docPath);
    return deleteDoc(docRef);
  }


  //#region Private methods
  private getItemPath(id: string): string {
    return `${this._collectionName}/${id}`;
  }
  //#endregion Private methods

}
