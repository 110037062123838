import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { IBar } from 'app/models/bar';
import * as AppState from '../app.state';
import * as BarActions from './actions'

export interface State extends AppState.State {
  bar: IBar | null;
  error: string;
}

const initialBarState: State = {
  bar: null,
  error: ''
};

export const stateName = 'bar';

//#region Reducers
export const reducer = createReducer<State>(
  initialBarState,
  on(BarActions.loadBarSuccess, (state, action): State => {
    return {
      ...state,
      bar: action.bar,
      error: ''
    };
  }),
  on(BarActions.loadBarFailure, (state, action): State => {
    return {
      ...state,
      bar: null,
      error: action.error
    };
  }),

);
//#endregion Reducers

// #region Selectors
const getBarFeatureState = createFeatureSelector<State>(stateName);

export const getBar = createSelector(
  getBarFeatureState,
  state => state.bar
);


export const getBarId = createSelector(
  getBarFeatureState,
  state => state.bar?.id
);

export const isWashingEnabled = createSelector(
  getBar,
  state => !!state?.useWashing
);

export const displayFullscreenToggle = createSelector(
  getBar,
  state => !!state?.displayFullscreenToggle
);
export const displayStatistics = createSelector(
  getBar,
  state => !!state?.displayStatistics
);

export const displayKegPercent = createSelector(
  getBar,
  state => !!state?.displayKegPercent
);

// #endregion Selectors
