import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { EMPTY, Observable, Observer, of } from 'rxjs';
import { IPlaato } from '../../models/plaato';
import { catchError } from 'rxjs/operators';
import { ToastService } from '../toast.service';

@Injectable({
  providedIn: 'root'
})
export class PlaatoExternalService {

  private endpoint = 'https://plaato.blynk.cc/';

  constructor(private http: HttpClient,
    private toastService: ToastService) { }

  getLastPourVolumeString(apiKey: string): Observable<string> {
    const route = apiKey + '/get/v47';
    return  this.getByRoute(route);
  }

  getBeerPercentLeft(apiKey: string): Observable<number> {
    const route = apiKey + '/get/v48';
    return  this.getByRoute(route);
  }

  isPouring(apiKey: string): Observable<number> {
    const route = apiKey + '/get/v49';
    return  this.getByRoute(route);
  }

  getAmountLeft(apiKey: string): Observable<string> {
    const route = apiKey + '/get/v51';
    return  this.getByRoute(route);
  }

  getTempRaw(apiKey: string): Observable<number> {
    const route = apiKey + '/get/v56';
    return  this.getByRoute(route);
  }

  getLastPour(apiKey: string): Observable<number> {
    const route = apiKey + '/get/v59';
    return  this.getByRoute(route);
  }
  
  getEmptyKegWeight(apiKey: string): Observable<number> {
    const route = apiKey + '/get/v62';
    return  this.getByRoute(route);
  }

  getBeerStyle(apiKey: string): Observable<string> {
    const route = apiKey + '/get/v64';
    return  this.getByRoute(route);
  }

  getOG(apiKey: string): Observable<number> {
    const route = apiKey + '/get/v65';
    return  this.getByRoute(route);
  }

  getFG(apiKey: string): Observable<number> {
    const route = apiKey + '/get/v66';
    return  this.getByRoute(route);
  }

  getKegDate(apiKey: string): Observable<string> {
    const route = apiKey + '/get/v67';
    return  this.getByRoute(route);
  }

  getABV(apiKey: string): Observable<number> {
    const route = apiKey + '/get/v68';
    return  this.getByRoute(route);
  }

  getTempString(apiKey: string): Observable<string> {
    const route = apiKey + '/get/v69';
    return  this.getByRoute(route);
  }

  getUnitType(apiKey: string): Observable<number> {
    const route = apiKey + '/get/v71';
    return  this.getByRoute(route);
  }

  getBeerLeftUnit(apiKey: string): Observable<string> {
    const route = apiKey + '/get/v74';
    return  this.getByRoute(route);
  }

  getMeasureTypeUnit(apiKey: string): Observable<number> {
    const route = apiKey + '/get/v75';
    return  this.getByRoute(route);
  }

  getMaxKegVolume(apiKey: string): Observable<number> {
    const route = apiKey + '/get/v76';
    return  this.getByRoute(route);
  }

  getWifiStrength(apiKey: string): Observable<number> {
    const route = apiKey + '/get/v81';
    return  this.getByRoute(route);
  }

  getVolumeUnit(apiKey: string): Observable<string> {
    const route = apiKey + '/get/v82';
    return  this.getByRoute(route);
  }

  getLeakDetection(apiKey: string): Observable<number> {
    const route = apiKey + '/get/v83';
    return  this.getByRoute(route);
  }

  getMinTemp(apiKey: string): Observable<number> {
    const route = apiKey + '/get/v86';
    return  this.getByRoute(route);
  }

  getMaxTemp(apiKey: string): Observable<number> {
    const route = apiKey + '/get/v87';
    return  this.getByRoute(route);
  }

  getBeerOrCo2(apiKey: string): Observable<number> {
    const route = apiKey + '/get/v88';
    return  this.getByRoute(route);
  }

  getScaleSensitivity(apiKey: string): Observable<number> {
    const route = apiKey + '/get/v89';
    return  this.getByRoute(route);
  }

  getFirmware(apiKey: string): Observable<number> {
    const route = apiKey + '/get/v93';
    return  this.getByRoute(route);
  }


  getPin(apiKey: string, pin: string): Observable<string> {
    const route = apiKey + '/get/' + pin;
    return  this.getByRoute(route);
  }

  private getByRoute<T>(route: string): Observable<T> {
    const url = `${this.endpoint}${route}`;
    return this.http.get<T>(url)
    .pipe(catchError(() => {
      this.toastService.error('Plaato error, check your settings');     
      return EMPTY;
    }));
  }
}
