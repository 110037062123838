import { createAction, props } from '@ngrx/store';
import { IOnTap2 } from 'app/models/on-tap';

export const clearAllItems = createAction('[DalHelper] Clear All OnTap2');
export const loadItems = createAction('[TapTv Advanced List Page] Load OnTap2', props<{ barId: string }>());
export const loadItemsSuccess = createAction('[OnTap Advanced API] Load OnTap2 Success', props<{ items: IOnTap2[] }>());
export const loadItemsFailure = createAction('[OnTap Advanced API] Load OnTap2 Failure', props<{ error: any }>());


export const updateItem = createAction('[TapTv Advanced Page] Update Item', props<{ item: IOnTap2 }>());
export const updateItemSuccess = createAction('[TapTv Advanced API] Update Items Success', props<{ item: IOnTap2 }>());
export const updateItemFailure = createAction('[TapTv Advanced API] Update Items Failure', props<{ error: any }>());

export const addItem = createAction('[TapTv Advanced Page] Add Item', props<{ id: string, item: IOnTap2 }>());
export const addItemSuccess = createAction('[TapTv Advanced API] Add Items Success', props<{ item: IOnTap2 }>());
export const addItemFailure = createAction('[TapTv Advanced API] Add Items Failure', props<{ error: any }>());


export const deleteItem = createAction('[TapTv Advanced Page] Delete Item', props<{ id: string }>());
export const deleteItemSuccess = createAction('[TapTv Advanced API] Delete Items Success');
export const deleteItemFailure = createAction('[TapTv Advanced API] Delete Items Failure', props<{ error: any }>());


export const setItem = createAction('[TapTv Advanced Page] Set Selected Item', props<{ id: string }>());
export const setItemFromListPage = createAction('[TapTv Advanced List Page] Set Selected Item', props<{ id: string }>());


export const softUpdate = createAction('[TapTv Advanced API] Soft Update Item', props<{ id: string, data: any, uid: string }>());
export const softUpdateSuccess = createAction('[TapTv Advanced API] Soft Update TapTv Advanced Success', props<{ id: string, data: any }>());
export const softUpdateFailure = createAction('[TapTv Advanced API] Soft Update TapTv Advanced Failure', props<{ error: any }>());


