import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastService } from 'app/services/toast.service';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import * as fromActions from './actions'
import * as fromPriceActions from '../stripePrice/actions'
import { StripeProductService } from 'app/services/stripe/stripe-product.service';
import { Store } from '@ngrx/store';


@Injectable()
export class Effects {


  stripeService = inject(StripeProductService);

  constructor(
    private store: Store,
    private actions$: Actions,
    private toastService: ToastService
  ) { }

  // getItem$ = createEffect(() => {
  //   return this.actions$
  //     .pipe(
  //       ofType(fromActions.loadItem),
  //       mergeMap((action) => this.service.getItem(action.id)
  //         .pipe(
  //           map(item => fromActions.loadItemSuccess({ item: item })),
  //           catchError(error => of(fromActions.loadItemFailure({ error })))
  //         )
  //       )
  //     );
  // });



  getItems$ = createEffect(() => {

    return this.actions$
      .pipe(
        ofType(fromActions.loadItems),
        mergeMap(() => this.stripeService.getProductsAndPrices()),
        map(item => {
          this.store.dispatch(fromPriceActions.loadItemsSuccess({ items: item.prices }));
          return fromActions.loadItemsSuccess({ items: item.products });
        }),
        catchError(error => of(fromActions.loadItemFailure({ error })))
      );
  });



}
